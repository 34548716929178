
export const nonPriorityFields = [
  'name',
  'description',
  'price',
  'imagePath',
  'manufacturerLink',
  'distributorLink',
  'reviews'
];
