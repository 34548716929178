// @flow

import * as React from 'react';
import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';

import type { StyledProps } from '../../utilities/types';

type Pane = {
  menuItem: string,
  render: void => React.Element<typeof Tab.Pane>
};

type UnstyledDescriptionProps = {
  panes: Pane[],
  defaultActiveIndex?: number
};

type DescriptionProps = StyledProps & UnstyledDescriptionProps;

class Description extends React.Component<DescriptionProps> {
  constructor(props) {
    super(props);
    const { defaultActiveIndex } = this.props;
    this.state = { activeIndex: defaultActiveIndex || 0 };
  }

  componentDidUpdate(prevProps) {
    const { defaultActiveIndex } = this.props;
    if (defaultActiveIndex !== prevProps.defaultActiveIndex) {
      this.setState({ activeIndex: defaultActiveIndex });
    }
  }

  handleTabChange = (e, data) => {
    this.setState({ activeIndex: data.activeIndex });
  };

  render() {
    const { className, panes } = this.props;
    const { activeIndex } = this.state;
    return (
      <div className={className}>
        <Tab
          menu={{ secondary: true, pointing: true }}
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}
          panes={panes}
        />
      </div>
    );
  }
}

const StyledDescription: React.ComponentType<UnstyledDescriptionProps> = styled(Description)`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .ui.container.tab {
    height: 20vh;
    overflow-y: hidden;

    &:hover {
      overflow-y: auto;
    }
  }

  .ui.pointing.secondary.menu .item {
    flex: 1;
  }
`;

export default StyledDescription;
