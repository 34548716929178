// @flow

import * as React from 'react';
import styled from 'styled-components';

import Video from '../../../videos/headphone-mobile-ui.mov';

import type { StyledProps } from '../../../utilities/types';

function AppPreview({ className }: StyledProps) {
  return (
    <div className={className}>
      <div className="browser-window">
        <div className="browser-title-bar">
          <div className="browser-close-button" />
          <div className="browser-close-button" />
          <div className="browser-close-button" />
        </div>
        <div className="video-container">
          <video autoPlay loop muted playsInline preload="none" width="100%">
            <source src={Video} type="video/mp4;" />
          </video>
        </div>
      </div>
    </div>
  );
}

const StyledAppPreview: React.ComponentType<{}> = styled(AppPreview)`
  .browser-window {
    width: 60vw;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 40px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);

    .browser-title-bar {
      height: 25px;
      border: 1px solid hsl(15, 100%, 45%);
      display: flex;
      align-items: center;
      padding: 0 10px;
      background-color: hsl(15, 100%, 85%);

      .browser-close-button {
        width: 7px;
        height: 7px;
        margin-right: 3px;
        border: 4px solid hsl(15, 100%, 45%);
        border-radius: 50%;
      }
    }

    .video-container {
      overflow: hidden;
      border: 1px solid hsl(15, 100%, 45%);
      border-top: none;
    }
  }
`;

export default StyledAppPreview;
