// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Pros, Cons } from '../../shared/Choice';

import type { StyledProps } from '../../../utilities/types';

type UnstyledChoiceDetailsProps = {
  pros: string[],
  cons: string[]
};

type ChoiceDetailsProps = StyledProps & UnstyledChoiceDetailsProps;

function ChoiceDetails({ className, pros, cons }: ChoiceDetailsProps) {
  return (
    <div className={className}>
      <Pros pros={pros} />
      <Cons cons={cons} />
    </div>
  );
}

const StyledChoiceDetails: React.ComponentType<UnstyledChoiceDetailsProps> = styled(ChoiceDetails)`
  max-width: 70vw;
  margin: 0 auto;
  line-height: 1.4em;
`;

export default StyledChoiceDetails;
