// @flow

import * as React from 'react';
import { Container, Image, Segment } from 'semantic-ui-react';

import NotificationModal from '../../shared/NotificationModal';

import fillForms from '../../../images/undraw_fill_forms_yltj.svg';

const NoResultsSegment = () => {
  return (
    <Segment placeholder>
      <div className="drawing-container">
        <Image src={fillForms} fluid />
      </div>
      <div className="text-container">
        <Container fluid text>
          {"Sorry! We couldn't find anything you'll like in this price range."}
        </Container>
      </div>
      <div className="text-container secondary">
        <Container fluid text>
          {"Click below to notify us, and we'll find you a pair you'll like as soon as we can."}
        </Container>
      </div>
      <NotificationModal />
    </Segment>
  );
};

export default NoResultsSegment;
