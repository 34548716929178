// @flow

import * as React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { mobileBuyingGuide, closedPill } from '../../../utilities/analytics';

import Pill from './Pill';

import type { Priority, StyledProps } from '../../../utilities/types';

type UnstyledPillContainerProps = {
  id: string,
  pills: Priority[],
  closePill: ({ id: number }) => void
};

type PillContainerProps = UnstyledPillContainerProps & StyledProps;

// The container that contains all selected pills. User can drag and drop to order pills.
function PillContainer({ className, id, pills, closePill }: PillContainerProps) {
  // TODO: change to flexbox for even spacing on all devices
  return (
    <div className={className}>
      <Droppable droppableId={id}>
        {provided => (
          <div ref={provided.innerRef} style={{ height: '70vh' }}>
            {pills.map((pill, index) => (
              <Draggable key={pill.id} draggableId={pill.id.toString()} index={index}>
                {innerProvided => (
                  <Pill
                    small
                    provided={innerProvided}
                    text={pill.title}
                    onClick={() => {
                      closePill({ id: pill.id });
                      ReactGA.event({
                        category: mobileBuyingGuide,
                        action: closedPill,
                        value: pill.id
                      });
                    }}
                    index={index}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

const StyledPillContainer: React.ComponentType<UnstyledPillContainerProps> = styled(PillContainer)`
  margin-top: 10px;
`;

export default StyledPillContainer;
