// @flow

import closedBack from '../images/tomasz-gawlowski-YDZPdqv3FcA-unsplash.jpg';
import openBack from '../images/alphacolor-66JMudIjDTw-unsplash.jpg';

import type { ChoiceSet } from '../utilities/types';

const openClosedChoice: ChoiceSet = {
  question: [
    { formatting: 'none', content: 'Are you looking for ' },
    { formatting: 'emphasis', content: 'open-back ' },
    { formatting: 'none', content: 'or ' },
    { formatting: 'emphasis', content: 'closed-back ' },
    { formatting: 'none', content: 'headphones?' }
  ],
  elementID: 'open-closed',
  icon: 'headphones',
  choices: [
    {
      id: 24,
      name: 'Closed-Back',
      subtitle: '',
      description:
        'They try to prevent sound from passing through their backs. Prevents others around you from hearing your audio, and prevents you from hearing external sounds, but typically provides inferior sound quality for music. The right option for portable use, or use in an office.',
      pros: ['no sound leakage', 'can be used in public settings', 'can be used in noisy places'],
      cons: ['inferior sound quality for music', 'more fatiguing for long listening sessions'],
      imagePath: closedBack
    },
    {
      id: 25,
      name: 'Open-Back',
      subtitle: '',
      description:
        'They have open grates or vents to allow air and sound to pass through their backs. Makes them unsuitable for listening in noisy or public places, but provides superior sound quality.',
      pros: ['best possible sound quality', 'more comfortable for long listening sessions'],
      cons: [
        'sound leakage',
        'not suitable for use in public settings',
        'not suitable for use in noisy places'
      ],
      imagePath: openBack
    }
  ]
};

export default openClosedChoice;
