// @flow

import * as React from 'react';
import styled from 'styled-components';

import { List, Checkbox, Icon, Popup } from 'semantic-ui-react';

type ChoiceItemProps = {
  name: string,
  selected: boolean,
  onChange: (e: any, data: { checked: boolean }) => void,
  description: string
};

function ChoiceItem(props: ChoiceItemProps) {
  const { name, selected, onChange, description, className } = props;
  return (
    <div className={className}>
      <List.Item>
        <List.Content className="info-icon-container">
          <Popup
            trigger={<Icon size="small" circular name="info" />}
            content={description}
            size="tiny"
            basic
            position="top right"
            style={{ maxWidth: '15vw', fontStyle: 'italic' }}
          />
        </List.Content>
        <List.Content>
          <Checkbox label={name} checked={selected} onChange={onChange} />
        </List.Content>
      </List.Item>
    </div>
  );
}

const StyledListItem = styled(ChoiceItem)`
  .item {
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;

    .ui.checkbox {
      width: 85%;
    }

    .info-icon-container {
      position: absolute;
      right: 0px;
      bottom: 5px;
    }
  }
`;

export default StyledListItem;
