// @flow

import * as React from 'react';
import { Pagination as SemanticPagination } from 'semantic-ui-react';
import styled from 'styled-components';

import type { StyledProps, ClickHandler } from '../../../utilities/types';

type UnstyledRecommendationPaginationProps = {
  index: number,
  changePage: ClickHandler,
  totalPages: number
};

type RecommendationPaginationProps = StyledProps & UnstyledRecommendationPaginationProps;

function RecommendationPagination({
  index,
  changePage,
  className,
  totalPages
}: RecommendationPaginationProps) {
  return (
    <SemanticPagination
      className={className}
      activePage={index}
      firstItem={null}
      lastItem={null}
      totalPages={totalPages}
      onPageChange={(_, { activePage }) => changePage(activePage)}
    />
  );
}

type StyledRPType = React.ComponentType<UnstyledRecommendationPaginationProps>;
const StyledRecommendationPagination: StyledRPType = styled(RecommendationPagination)`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export default StyledRecommendationPagination;
