// @flow

import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';

import { writeRecs } from '../../../utilities/utilities';
import * as store from '../../../utilities/store';
import {
  desktopBuyingGuide,
  selectedPriceRange,
  updatedRecommendations
} from '../../../utilities/analytics';

import type { RecommendationMap, StyledProps, PriorityMap, Action } from '../../../utilities/types';

import SectionHeader from '../SectionHeader';
import NoAnswersSegment from './NoAnswersSegment';
import RecommendationTab from './RecommendationTab';

import { findNonEmptyBracketIndex } from '../../utilities/utilities';

const bracketToTitle = {
  under_50: 'Under $50',
  under_100: 'Under $100',
  under_200: 'Under $200',
  under_400: 'Under $400',
  over_400: 'Over $400'
};

type BaseRecommendationPaneProps = {
  recommendationMap: RecommendationMap,
  noPriorities: boolean
};

type UnstyledRecommendationPaneProps = BaseRecommendationPaneProps & {
  priorities: PriorityMap,
  populateRecs: Action
};

type RecommendationPaneProps = StyledProps & UnstyledRecommendationPaneProps;

class RecommendationPane extends React.Component {
  constructor(props: RecommendationPaneProps) {
    super(props);
    this.state = { activeIndex: findNonEmptyBracketIndex(props.recommendationMap) };
  }

  componentDidUpdate = prevProps => {
    const { recommendationMap } = this.props;

    if (!_.isEqual(prevProps.recommendationMap, recommendationMap)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ activeIndex: findNonEmptyBracketIndex(recommendationMap) });
    }
  };

  handleTabChange = (e, { index }) => {
    this.setState({ activeIndex: index });
    ReactGA.event({
      category: desktopBuyingGuide,
      action: selectedPriceRange,
      value: index
    });
  };

  render() {
    const { className, noPriorities, recommendationMap, populateRecs, priorities } = this.props;
    const { activeIndex } = this.state;

    return (
      <div className={className} id="recommendation-pane">
        <SectionHeader
          icon="star"
          content={[
            { formatting: 'none', content: 'Our ' },
            { formatting: 'emphasis', content: 'recommendations ' },
            { formatting: 'none', content: 'for you!' }
          ]}
          // buttonText="Update"2
          // buttonAction={() => {
          //   populateRecs();
          //   writeRecs(priorities);
          //   ReactGA.event({
          //     category: desktopBuyingGuide,
          //     action: updatedRecommendations
          //   });
          // }}
        />
        {noPriorities ? (
          <NoAnswersSegment />
        ) : (
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={_.toPairs(bracketToTitle).map(([bracket, title]) => ({
              menuItem: title,
              render: () => (
                <RecommendationTab recommendationMap={recommendationMap} bracket={bracket} />
              )
            }))}
            activeIndex={activeIndex}
            onTabChange={this.handleTabChange}
          />
        )}
      </div>
    );
  }
}

type StyledRecommendationPaneType = React.ComponentType<UnstyledRecommendationPaneProps>;

const StyledRecommendationPane: StyledRecommendationPaneType = styled(RecommendationPane)`
  width: 60vw;
  .ui.placeholder.segment {
    width: 60vw;
  }
  .drawing-container {
    width: 15vw;
    margin: 50px auto 25px auto;
  }
  .text-container {
    width: 30vw;
    margin: 25px auto 50px auto;
    font-weight: 600;
  }
  .secondary {
    font-weight: 400;
    margin-top: -25px;
    .ui.text.fluid.container {
      font-size: 0.96rem;
    }
  }
  .ui.primary.button.segment-button {
    margin-top: -25px;
    margin-bottom: 50px;
  }
`;

export default (connect(({ priorities }) => ({ priorities }), { populateRecs: store.populateRecs })(
  StyledRecommendationPane
): React.AbstractComponent<BaseRecommendationPaneProps>);
