import * as React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

import { Header } from 'semantic-ui-react';

function Heading({ className }) {
  return (
    <div className={className}>
      <Element name="heading">
        <Header as="h1" textAlign="center">
          Find the right set of headphones for you.
          <Header.Subheader>
            Speed through our interactive quiz, and we&apos;ll recommend a couple models in each
            price range, along with links to third-party reviews.
          </Header.Subheader>
        </Header>
      </Element>
    </div>
  );
}

const StyledHeading = styled(Heading)`
  width: 60vw;
  margin: auto;
  padding-top: 30px;
  .sub.header {
    padding-top: 20px !important;
    width: 45vw;
    margin: auto !important;
  }
`;

export default StyledHeading;
