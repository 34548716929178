export const desktopBuyingGuide = 'Desktop Buying Guide';
export const mobileBuyingGuide = 'Mobile Buying Guide';

export const requestedRecommendations = 'Requested Recommendations';

export const selectedChoice = 'Selected Choice';
export const deselectedChoice = 'Deselected Choice';

export const closedPill = 'Closed Pill';

export const reorderedPriorities = 'Reordered Priorities';

export const addedPriority = 'Added Priority';
export const removedPriority = 'Removed Priority';

export const selectedPriceRange = 'Selected Price Range';

export const updatedRecommendations = 'Updated Recommendations';

export const clickedDownArrow = 'Clicked Down Arrow';

export const clickedTakeQuizButton = 'Clicked "Take Quiz" Button';

export const recommendationTabChanged = 'Changed Recommendation Tab';

export const searchedPriorities = 'Searched Priorities';