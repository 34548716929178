// @flow

import * as React from 'react';
import { Step as SemanticStep } from 'semantic-ui-react';

type StepProps = {
  active: boolean,
  onClick: any => void,
  title?: string,
  description?: string,
  children?: React.Node,
  className?: string
};

function Step({ active, onClick, title, description, children, className }: StepProps) {
  return (
    <SemanticStep active={active} onClick={onClick} className={className}>
      <SemanticStep.Content>
        <SemanticStep.Title>{title}</SemanticStep.Title>
        <SemanticStep.Description>{description}</SemanticStep.Description>
        {children}
      </SemanticStep.Content>
    </SemanticStep>
  );
}

Step.defaultProps = {
  title: '',
  description: '',
  children: null,
  className: ''
};

export default Step;
