import * as React from 'react';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';

import { Link } from 'react-router-dom';
import { Button, Sticky, Image } from 'semantic-ui-react';

import logo from '../../images/choosy_logo.svg';

function MenuBar({ className, containerRef, onOpenGuide }) {
  return (
    <Sticky context={containerRef}>
      <div className={className}>
        <div>
          <ScrollLink to="heading" smooth duration={300} offset={-100}>
            <Image src={logo} size="tiny" />
          </ScrollLink>
        </div>
        <ScrollLink to="about" smooth duration={300} offset={-200}>
          <Link to="/">About</Link>
        </ScrollLink>
        <Button primary onClick={onOpenGuide}>
          Take the quiz
        </Button>
      </div>
    </Sticky>
  );
}

const StyledMenuBar = styled(MenuBar)`
  display: flex;
  padding: 5px 8px;
  align-items: center;
  font-weight: 700;
  background-color: #fff;

  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  div a {
    margin-left: auto;
    padding: 0px;
  }

  a {
    margin-left: auto;
    padding: 10px 25px;
    cursor: pointer;
    a {
      padding: 0px;
    }
  }
  button {
    padding: 10px 25px;
    a {
      color: #fff;
      padding: 10px 0px;
    }
  }
`;

export default StyledMenuBar;
