/* eslint-disable jsx-a11y/interactive-supports-focus */
// @flow

import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

type UnstyledPaginationProps = {
  pageCount: number,
  activePageIndex: number,
  changePage: number => void
};

type PaginationProps = UnstyledPaginationProps & {
  className: string
};

// TODO: remove ESLint skips, add accessibility accordingly
const Pagination = ({ className, pageCount, activePageIndex, changePage }: PaginationProps) => {
  const hasPreviousPage = activePageIndex > 1;
  const hasNextPage = activePageIndex < pageCount;
  return (
    <div className={className}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        onClick={() => {
          if (hasPreviousPage) changePage(activePageIndex - 1);
        }}
      >
        <div style={!hasPreviousPage ? { opacity: 0.45 } : {}}>
          <Icon name="angle left" size="big" disabled={!hasPreviousPage} />
          PREVIOUS
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        role="button"
        onClick={() => {
          if (hasNextPage) changePage(activePageIndex + 1);
        }}
      >
        <div style={!hasNextPage ? { opacity: 0.45 } : {}}>
          NEXT
          <Icon name="angle right" size="big" disabled={!hasNextPage} />
        </div>
      </div>
    </div>
  );
};

type StyledPaginationType = React.ComponentType<UnstyledPaginationProps>;
const StyledPagination: StyledPaginationType = styled(Pagination)`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  height: 35px;

  div {
    flex: 1;
    div {
      position: relative;
      transform: translateX(-50%);
      left: 75%;
      color: hsl(15, 65%, 30%);
    }
  }

  i {
    color: hsl(15, 100%, 45%);
  }
`;

export default StyledPagination;
