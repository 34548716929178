import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { Responsive } from 'semantic-ui-react';

import DesktopLandingPage from './components/desktop/LandingPage/LandingPage';
import MobileLandingPage from './components/mobile/LandingPage/LandingPage';

import * as store from './utilities/store';
import { nonPriorityFields } from './utilities/constants';
import { parseHeadphoneData } from './utilities/utilities';

const headphoneData = require('./data/headphones.json');

class LandingPage extends React.Component {
  componentDidMount() {
    const { setHeadphoneData } = this.props;
    const headphones = parseHeadphoneData(headphoneData).map(entry => ({
      ..._.pick(entry, nonPriorityFields),
      priorities: _.omit(entry, nonPriorityFields)
    }));
    setHeadphoneData(headphones);
  }

  render() {
    return (
      <>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <DesktopLandingPage />
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} style={{ height: '100%' }}>
          <MobileLandingPage />
        </Responsive>
      </>
    );
  }
}

export default connect(null, {
  setHeadphoneData: store.setHeadphoneData
})(LandingPage);
