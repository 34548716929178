// @flow

import wired from '../images/michael-mroczek-195362-unsplash.jpg';
import wireless from '../images/malte-wingen-381988-unsplash.jpg';

import type { ChoiceSet } from '../utilities/types';

const connectionChoice: ChoiceSet = {
  icon: 'plug',
  elementID: 'connection',
  question: [
    { formatting: 'none', content: 'What ' },
    { formatting: 'emphasis', content: 'connection type ' },
    { formatting: 'none', content: 'do you prefer?' }
  ],
  choices: [
    {
      id: 22,
      name: 'Wired',
      subtitle: '',
      description: 'Connected to your device via a cable, usually using a 3.5mm headphone jack.',
      pros: ['best sound quality', 'no charging needed'],
      cons: ['cable can get in the way', 'unsuitable for physical activity', 'less portable'],
      imagePath: wired,
      imageCredit: {
        name: 'Michael Mroczek',
        link:
          'https://unsplash.com/@michaelmroczek?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    },
    {
      id: 23,
      name: 'Wireless',
      subtitle: '',
      description: 'Connects to your device via Bluetooth.',
      pros: ['no cables', 'well suited to physical activity', 'increased portability'],
      cons: [
        'limited sound quality',
        'needs charging',
        'can have connection troubles',
        'can drain your device battery faster'
      ],
      imagePath: wireless,
      imageCredit: {
        name: 'Malte Wingen',
        link:
          'https://unsplash.com/@maltewingen?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    }
  ]
};

export default connectionChoice;
