// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'semantic-ui-react';

import type { ClickHandler, StyledProps } from '../../utilities/types';

type DownButtonProps = StyledProps & {
  onClick: ClickHandler
};

function DownButton({ className, onClick }: DownButtonProps) {
  return (
    <Button circular icon="chevron down" className={className} onClick={onClick} color="orange" />
  );
}

const StyledDownButton: React.ComponentType<{}> = styled(DownButton)`
  display: block !important;
  margin: 2vh auto 0 auto !important;
`;

export default StyledDownButton;
