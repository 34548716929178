// @flow

import _ from 'lodash';
import { scroller } from 'react-scroll';

import type { RecommendationMap } from '../../utilities/types';

export function scrollTo(label: string, offset: number) {
  return () =>
    scroller.scrollTo(label, {
      smooth: true,
      duration: 300,
      offset
    });
}

export function scrollToSection(label: string) {
  return scrollTo(label, -70);
}

export function findNonEmptyBracketIndex(recommendationMap: RecommendationMap) {
  const recValues = Object.values(recommendationMap);
  const recIndexes = [];
  _.forEach(recValues, (recs, i) => {
    if (recs.length !== 0) recIndexes.push(i);
  });

  const indexOrdering = [2, 3, 4, 1, 0];
  for (let i = 0; i < indexOrdering.length; i += 1) {
    if (_.includes(recIndexes, indexOrdering[i])) return indexOrdering[i];
  }

  return 0;
}
