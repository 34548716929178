// @flow

import * as React from 'react';
import { Input } from 'semantic-ui-react';

import type { ClickHandler } from '../../../utilities/types';

type PriorityInputProps = {
  isLoading: boolean,
  handleSearchChange: ClickHandler
};

const PriorityInput = ({ isLoading, handleSearchChange }: PriorityInputProps) => {
  return (
    <Input
      fluid
      icon="search"
      placeholder="Search..."
      loading={isLoading}
      onChange={handleSearchChange}
    />
  );
};

export default PriorityInput;
