// @flow

import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { List } from 'semantic-ui-react';

import * as store from '../../../utilities/store';
import { isSelected } from '../../../utilities/utilities';
import { mobileBuyingGuide, selectedChoice, deselectedChoice } from '../../../utilities/analytics';

import ListItem from '../ListItem';
import SectionHeader from '../SectionHeader';
import ChoiceDetails from './ChoiceDetails';

import priorities from '../../../data/priorities';
import type { ChoiceSet, Priority, Action, StyledProps } from '../../../utilities/types';

type BaseChoiceProps = ChoiceSet & {};

type UnstyledChoiceProps = BaseChoiceProps & {
  needPriorities: Priority[],
  selectChoice: Action,
  deselectChoice: Action
};

type ChoiceProps = StyledProps & UnstyledChoiceProps;

type ChoiceState = {
  infoIndex: number
};

class Choice extends React.Component<ChoiceProps, ChoiceState> {
  constructor(props) {
    super(props);
    this.state = { infoIndex: -1 };
  }

  componentDidUpdate(prevProps) {
    const { question } = this.props;
    if (question !== prevProps.question) {
      this.setState({ infoIndex: -1 });
    }
  }

  openDrawer = index => {
    this.setState(({ infoIndex }) =>
      infoIndex === index ? { infoIndex: -1 } : { infoIndex: index }
    );
  };

  render() {
    const {
      icon,
      question,
      choices,
      needPriorities,
      selectChoice: _selectChoice,
      deselectChoice: _deselectChoice,
      className
    } = this.props;
    const { infoIndex } = this.state;

    const selectChoice = e => {
      _selectChoice(e);
      ReactGA.event({
        category: mobileBuyingGuide,
        action: selectedChoice,
        label: e.title,
        value: e.id
      });
    };

    const deselectChoice = e => {
      _deselectChoice(e);
      ReactGA.event({
        category: mobileBuyingGuide,
        action: deselectedChoice,
        label: e.title,
        value: e.id
      });
    };

    const selected = _.filter(choices, choice => isSelected(needPriorities, choice.id));

    return (
      <div className={className}>
        <SectionHeader icon={icon} content={question} />
        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          <List relaxed>
            {choices.map((choice, index) => {
              const f = selected.includes(choice) ? deselectChoice : selectChoice;
              return (
                <ListItem
                  key={index}
                  showInfo={index === infoIndex}
                  openDrawer={() => this.openDrawer(index)}
                  selected={selected.includes(choice)}
                  onChange={() => f(_.find(priorities, priority => priority.id === choice.id))}
                  drawerContent={<ChoiceDetails pros={choice.pros} cons={choice.cons} />}
                  {...choice}
                />
              );
            })}
          </List>
        </div>
      </div>
    );
  }
}

const StyledChoice: React.ComponentType<UnstyledChoiceProps> = styled(Choice)`
  width: 80vw;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default (connect(
  state => {
    return { needPriorities: state.priorities.need };
  },
  {
    selectChoice: store.selectChoice,
    deselectChoice: store.deselectChoice
  }
)(StyledChoice): React.AbstractComponent<BaseChoiceProps>);
