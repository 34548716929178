// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { Link, Element } from 'react-scroll';

import { scrollToSection } from '../../utilities/utilities';
import Nav from '../Nav/Nav';
import { writeRecs } from '../../../utilities/utilities';
import * as store from '../../../utilities/store';
import { desktopBuyingGuide, requestedRecommendations } from '../../../utilities/analytics';

import SectionFooter from './SectionFooter';
import DataModeSwitch from '../DataModeSwitch';

import type { PriorityMap, Action } from '../../../utilities/types';

type BasePageContainerProps = {
  children: React.Node[],
  priorities: PriorityMap
};

type UnstyledPageContainerProps = BasePageContainerProps & {
  populateRecs: Action
};

type PageContainerProps = UnstyledPageContainerProps & {
  className: string
};

type PageContainerState = {
  scrollY: number,
  ref: any
};

class PageContainer extends React.Component<PageContainerProps, PageContainerState> {
  constructor(props) {
    super(props);
    this.state = { scrollY: 0, ref: React.createRef() };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = (): void => {
    this.setState({ scrollY: window.pageYOffset });
  };

  render() {
    const { children, className, priorities, populateRecs } = this.props;
    const { scrollY, ref } = this.state;

    return (
      <div className={className}>
        <div className="right-pane" ref={ref}>
          <div>
            {children.map((child, index) => (
              <div key={index} style={{ overflowX: 'hidden' }}>
                <Element name={`scroll-element-${index}`}>
                  <div className="section-container">
                    {child}
                    {index < children.length - 1 ? (
                      <Link to={`scroll-element-${index + 1}`} smooth duration={300} offset={-60}>
                        <SectionFooter
                          onClick={
                            index === children.length - 2
                              ? () => {
                                  scrollToSection('scroll-element-5');
                                  populateRecs();
                                  writeRecs(priorities);
                                  ReactGA.event({
                                    category: desktopBuyingGuide,
                                    action: requestedRecommendations,
                                    label: 'Down Arrow'
                                  });
                                }
                              : null
                          }
                        />
                      </Link>
                    ) : null}
                  </div>
                </Element>
              </div>
            ))}
          </div>
        </div>
        {ref.current &&
        scrollY > ref.current.offsetTop - 100 &&
        scrollY < document.getElementById('about').offsetTop - 500 ? (
          <div className="left-pane">
            <DataModeSwitch />
            <Nav scrollY={scrollY} priorities={priorities} />
          </div>
        ) : null}
      </div>
    );
  }
}

const StyledPageContainer: React.ComponentType<UnstyledPageContainerProps> = styled(PageContainer)`
  .right-pane {
    position: relative;
    width: 75% !important;
    left: 25% !important;
    display: flex;
    align-items: center;
    overflow-x: hidden;

    .section-container {
      margin-bottom: 20vh;
    }
  }

  .left-pane {
    position: relative;
    width: 0% !important;
    left: 0 !important;
  }
`;

export default (connect(null, { populateRecs: store.populateRecs })(
  StyledPageContainer
): React.AbstractComponent<BasePageContainerProps>);
