// @flow

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

import LandingPage from './LandingPage';

ReactGA.initialize('UA-163976669-1', {});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
    </Switch>
  );
}

export default App;
