// @flow

import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { Header } from 'semantic-ui-react';

import type { StyledProps } from '../../utilities/types';

const formatText = (textRuns: FormattedText[]) => {
  return _.map(textRuns, ({ formatting, content }) =>
    formatting === 'emphasis' ? <span className="emphasis">{content}</span> : <span>{content}</span>
  );
};

type UnstyledSectionHeaderProps = {
  content: React.Node,
  icon: string,
  subheader?: React.Node
};

type SectionHeaderProps = StyledProps & UnstyledSectionHeaderProps;

function SectionHeader({ className, content, icon, subheader }: SectionHeaderProps) {
  return (
    <div className={className}>
      <Header size="medium">
        <Header.Content>
          {formatText(content)}
          <Header.Subheader>{subheader}</Header.Subheader>
        </Header.Content>
      </Header>
    </div>
  );
}

SectionHeader.defaultProps = {
  subheader: null
};

const StyledSectionHeader: React.ComponentType<UnstyledSectionHeaderProps> = styled(SectionHeader)`
  margin-top: 40px;

  span {
    font-weight: 100;
  }
  .emphasis {
    color: hsl(15, 100%, 45%);
    font-weight: bold;
  }
`;

export default StyledSectionHeader;
