// @flow

import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { Card, Button, Tab, Image, Icon, Popup, Container, List, Divider } from 'semantic-ui-react';
import ReactGA from 'react-ga';

import type { Recommendation, StyledProps } from '../../../utilities/types';

import Description from '../../shared/Description';

function importAll(r) {
  const images = {};
  _.forEach(r.keys(), item => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

// Import all image files in the headphones folder (regex matches every non-empty string)
// $FlowFixMe
const images: Object = importAll(require.context('../../../images/headphones', true, /.*/));

type UnstyledRecommendationCardProps = Recommendation & {
  bestOption: boolean
};

type RecommendationCardProps = StyledProps & UnstyledRecommendationCardProps;

function RecommendationCard({
  className,
  name,
  price,
  imagePath,
  description,
  manufacturerLink,
  distributorLink,
  reviews,
  bestOption
}: RecommendationCardProps) {
  const panes = [
    {
      menuItem: 'Summary',
      render: () => (
        <Tab.Pane as={Container}>
          <p>{description}</p>
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Learn More',
      render: () => (
        <Tab.Pane as={Container}>
          <List>
            <List.Item>
              <ReactGA.OutboundLink
                eventLabel={`Manufacturer: ${manufacturerLink.url}`}
                to={manufacturerLink.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {manufacturerLink.site}
              </ReactGA.OutboundLink>
            </List.Item>
            {!_.isEmpty(reviews) ? <Divider className="review-divider" /> : null}
            {_.map(reviews, review => (
              <List.Item>
                <ReactGA.OutboundLink
                  eventLabel={`Review: ${review.url}`}
                  to={review.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {review.site}
                </ReactGA.OutboundLink>
              </List.Item>
            ))}
          </List>
        </Tab.Pane>
      )
    }
  ];

  return (
    <Card className={className}>
      <Image src={images[`${imagePath}`]} wrapped ui={false} />
      {bestOption ? (
        <Popup
          content="Best for you!"
          trigger={
            <Icon
              name="star"
              size="large"
              color="yellow"
              style={{ position: 'absolute', padding: '10px' }}
            />
          }
        />
      ) : null}
      <Card.Content>
        <Card.Header>{name}</Card.Header>
      </Card.Content>
      <Card.Description>
        <Description panes={panes} />
      </Card.Description>
      <Card.Content extra>
        <div className="extra-content">
          <div>${price}</div>
          <Button className="buy-button" primary target="_blank">
            <ReactGA.OutboundLink
              eventLabel={`Buy: ${distributorLink.url}`}
              to={distributorLink.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Buy
            </ReactGA.OutboundLink>
          </Button>
        </div>
      </Card.Content>
    </Card>
  );
}

const StyledRecommendationCard = styled(RecommendationCard)`
  .extra-content {
    display: flex;
    div {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.85);
      font-size: 1.6em;
      vertical-align: middle;
      line-height: 36px;
      flex-grow: 2;
    }
  }

  .review-divider.review-divider.review-divider {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .description {
    padding: 0px 15px 10px;
    height: 200px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .buy-button {
    letter-spacing: 0.07em;
    a {
      color: #fff !important;
      text-decoration: none;
    }
  }
`;

export default StyledRecommendationCard;
