// @flow

import music from '../images/adrian-korte-76051-unsplash.jpg';
import movies from '../images/thibault-penin-767722-unsplash.jpg';
import gaming from '../images/hardik-sharma-560353-unsplash.jpg';
import phoneCalls from '../images/hassan-ouajbir-515815-unsplash.jpg';
import exercise from '../images/tikkho-maciel-2-_WkjmC8x4-unsplash.jpg';

import type { ChoiceSet } from '../utilities/types';

const useCaseChoice: ChoiceSet = {
  icon: 'music',
  elementID: 'use-case',
  question: [
    { formatting: 'none', content: 'What do you primarily plan to ' },
    { formatting: 'emphasis', content: 'use ' },
    { formatting: 'none', content: 'them for?' }
  ],
  choices: [
    {
      id: 18,
      name: 'Music',
      subtitle: '',
      description:
        "You'll use the headphones primarily for music. These headphones focus on providing good sound.",
      pros: ['good sound quality'],
      cons: [],
      imagePath: music,
      imageCredit: {
        name: 'Adrian Korte',
        link:
          'https://unsplash.com/@adkorte?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    },
    {
      id: 19,
      name: 'Movies',
      subtitle: '',
      description:
        "You'll use the headphones primarily for movies. They do a good job of producing a 'surround sound' effect, and are comfortable for long listening (and watching!) sessions.",
      pros: ['creates immersive feel', 'easier to wear over longer periods'],
      cons: ['large'],
      imagePath: movies,
      imageCredit: {
        name: 'Thibault Penin',
        link:
          'https://unsplash.com/@petibalt?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    },
    {
      id: 20,
      name: 'Gaming',
      subtitle: '',
      description:
        "You'll use the headphones primarily for gaming. They provide accurate surround sound for first- and third-person shooters and have good quality microphones for online play.",
      pros: ['good microphone'],
      cons: ['large'],
      imagePath: gaming,
      imageCredit: {
        name: 'Hardik Sharma',
        link:
          'https://unsplash.com/@v4ssu?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    },
    {
      id: 21,
      name: 'Phone Calls',
      subtitle: '',
      description:
        "You'll use the headphones primarily for placing phone calls. These will have better microphones than headphones in the other categories. If wireless, they'll also have good range.",
      pros: ['good microphone', 'long range (if wireless)'],
      cons: [],
      imagePath: phoneCalls,
      imageCredit: {
        name: 'Hassan OUAJBIR',
        link:
          'https://unsplash.com/@hazardos?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    },
    {
      id: 26,
      name: 'Exercise',
      subtitle: '',
      description:
        "You'd like the headphones to be suitable for exercise. These will usually be sweat-resistant and water-resistant.",
      pros: ['sweat-resistant', 'water-resistant'],
      cons: [],
      imagePath: exercise,
      imageCredit: {
        name: 'Tikkho Maciel',
        link:
          'https://unsplash.com/@tikkho?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    }
  ]
};

export default useCaseChoice;
