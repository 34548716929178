// @flow

import _ from 'lodash';
import * as db from './database';

import type { Priority, PriorityMap } from './types';

export const isSelected = (needPriorities: Priority[], priorityID: number): boolean => {
  return _.filter(needPriorities, priority => priority.id === priorityID).length !== 0;
};

const simplifyPriorities = (priorities: PriorityMap): any => {
  const simplifiedPriorities = { ...priorities };
  _(priorities)
    .entries()
    .forEach(([k, v]) => {
      // $FlowFixMe
      simplifiedPriorities[k] = _.map(v, ({ id, rankingType, title }) => ({
        id,
        rankingType,
        title
      }));
    });
  return simplifiedPriorities;
};

const getBrowserData = () => {
  const { width, height, availWidth, availHeight } = window.screen;
  return {
    userAgent: window.navigator.userAgent,
    screen: {
      width,
      height,
      availWidth,
      availHeight
    }
  };
};

export const writeRecs = (priorities: PriorityMap): void => {
  const simplifiedPriorities = simplifyPriorities(priorities);
  db.writeRecommendationRequest(getBrowserData(), simplifiedPriorities);
};

export const writeNotificationRequest = (email: string, priorities: PriorityMap): void => {
  const simplifiedPriorities = simplifyPriorities(priorities);
  db.writeNotificationRequest(getBrowserData(), email, simplifiedPriorities);
};

export const parseHeadphoneData = (headphoneData: Object): Object => {
  return _.map(headphoneData, headphoneDatum => {
    const { manufacturerLink, distributorLink, reviews } = headphoneDatum;
    return {
      ...headphoneDatum,
      manufacturerLink: JSON.parse(manufacturerLink),
      distributorLink: JSON.parse(distributorLink),
      reviews: JSON.parse(reviews)
    };
  });
};
