// @flow

import * as React from 'react';
import { Image, Container, Segment } from 'semantic-ui-react';

import fillForms from '../../../images/undraw_fill_forms_yltj.svg';

const NoAnswersSegment = () => {
  return (
    <Segment placeholder>
      <div className="drawing-container">
        <Image src={fillForms} fluid />
      </div>
      <div className="text-container">
        <Container fluid text>
          {"Oops! Looks like you haven't answered any questions."}
        </Container>
      </div>
      <div className="text-container secondary">
        <Container fluid text>
          {
            'Let us know what you care about in the sections above, click "Get Recommendations" on the left, and we\'ll have your recommendations ready here!'
          }
        </Container>
      </div>
    </Segment>
  );
};

export default NoAnswersSegment;
