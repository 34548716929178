// @flow

import * as React from 'react';
import { Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { mobileBuyingGuide, recommendationTabChanged } from '../../../utilities/analytics';

import RecommendationCard from './RecommendationCard';
import RecommendationPagination from './RecommendationPagination';
import NoResultsSegment from './NoResultsSegment';

import type { RecommendationMap, StyledProps } from '../../../utilities/types';

type UnstyledRecommendationTabProps = {
  recommendationMap: RecommendationMap,
  bracket: string
};

type RecommendationTabProps = StyledProps & UnstyledRecommendationTabProps;

type RecommendationTabState = {
  recommendationIndex: number
};

class RecommendationTab extends React.Component<RecommendationTabProps, RecommendationTabState> {
  constructor(props) {
    super(props);
    this.state = { recommendationIndex: 1 };
  }

  changePage = (pageIndex: number) => {
    this.setState({ recommendationIndex: pageIndex });
    ReactGA.event({
      category: mobileBuyingGuide,
      action: recommendationTabChanged,
      value: pageIndex
    });
  };

  render() {
    const { recommendationMap, bracket, className } = this.props;
    const { recommendationIndex } = this.state;
    const numberOfRecs = Math.min(recommendationMap[bracket].length, 3);
    const { name, price, imagePath, description, manufacturerLink, distributorLink, reviews } =
      recommendationMap[bracket].length >= recommendationIndex
        ? recommendationMap[bracket][recommendationIndex - 1]
        : {};
    return (
      <Tab.Pane className={className} style={{ border: 'none' }}>
        {recommendationMap[bracket].length === 0 ? (
          <NoResultsSegment />
        ) : (
          <>
            <RecommendationPagination
              index={recommendationIndex}
              changePage={this.changePage}
              totalPages={numberOfRecs}
            />
            <RecommendationCard
              name={name}
              price={price}
              imagePath={imagePath}
              description={description}
              manufacturerLink={manufacturerLink}
              distributorLink={distributorLink}
              bestOption={recommendationIndex === 1}
              reviews={reviews}
            />
          </>
        )}
      </Tab.Pane>
    );
  }
}

type StyledRecommendationTabType = React.ComponentType<UnstyledRecommendationTabProps>;
const StyledRecommendationTab: StyledRecommendationTabType = styled(RecommendationTab)`
  border: none;
  overflow-y: hidden;

  &:hover {
    overflow-y: auto;
  }

  height: 65vh;
  padding-top: 0px !important;
  .ui.pagination.menu {
    margin-bottom: 10px;
  }
`;

export default StyledRecommendationTab;
