// @flow

import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { Card, Button, Tab, Container } from 'semantic-ui-react';

import { Pros, Cons } from '../../shared/Choice';
import Description from '../../shared/Description';

import priorities from '../../../data/priorities';

import type { Action } from '../../../utilities/types';

type UnstyledChoiceCardProps = {
  description: string,
  pros: string[],
  cons: string[],
  imagePath: string,
  name: string,
  subtitle: string,
  buttonText: string,
  onClick: Action,
  id: number,
  detailsMode: boolean
};

type ChoiceCardProps = UnstyledChoiceCardProps & {
  className: string
};

function ChoiceCard(props: ChoiceCardProps) {
  const {
    description,
    pros,
    cons,
    className,
    imagePath,
    name,
    subtitle,
    buttonText,
    onClick,
    id,
    detailsMode
  } = props;
  const panes = [
    {
      menuItem: { icon: 'align left', content: 'Details' },
      render: () => (
        <Tab.Pane as={Container}>
          <p>{description}</p>
        </Tab.Pane>
      )
    },
    {
      menuItem: { icon: 'unordered list', content: 'Pros & Cons' },
      render: () => (
        <Tab.Pane as={Container}>
          <Pros pros={pros} />
          <Cons cons={cons} />
        </Tab.Pane>
      )
    }
  ];

  return (
    <Card
      className={className}
      image={imagePath}
      header={name}
      meta={subtitle}
      description={
        <div>
          <Description panes={panes} defaultActiveIndex={detailsMode ? 0 : 1} />
        </div>
      }
      extra={
        <Button
          primary
          content={buttonText}
          onClick={() => onClick(_.find(priorities, ['id', id]))}
          style={{ float: 'right' }}
        />
      }
    />
  );
}

const StyledChoiceCard: React.ComponentType<UnstyledChoiceCardProps> = styled(ChoiceCard)`
  box-shadow: ${props => (props.selected ? '0 3px 15px 0 #d4d4d5,0 0 0 0 #d4d4d5 !important' : '')};
  border: ${props => (props.selected ? '2px solid hsl(15, 90%, 70%) !important' : '')};
  background: ${props => (props.selected ? 'hsl(15, 90%, 97%) !important' : '')};
`;

export default StyledChoiceCard;
