// @flow

import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { Card } from 'semantic-ui-react';
import SectionHeader from '../SectionHeader';
import * as store from '../../../utilities/store';
import { isSelected } from '../../../utilities/utilities';
import { desktopBuyingGuide, selectedChoice, deselectedChoice } from '../../../utilities/analytics';

import ChoiceCard from './ChoiceCard';

import type { Priority, Action, ChoiceSet } from '../../../utilities/types';

type ChoiceProps = ChoiceSet & {
  className: string,
  elementID: string,
  needPriorities: Priority[],
  selectChoice: Action,
  deselectChoice: Action,
  detailsMode: boolean
};

let Choice = ({
  className,
  elementID,
  icon,
  question,
  choices: _choices,
  needPriorities,
  selectChoice: _selectChoice,
  deselectChoice: _deselectChoice,
  populateRecs,
  detailsMode
}: ChoiceProps) => {
  const selectChoice = e => {
    _selectChoice(e);
    populateRecs();
    ReactGA.event({
      category: desktopBuyingGuide,
      action: selectedChoice,
      label: e.title,
      value: e.id
    });
  };
  const deselectChoice = e => {
    _deselectChoice(e);
    populateRecs();
    ReactGA.event({
      category: desktopBuyingGuide,
      action: deselectedChoice,
      label: e.title,
      value: e.id
    });
  };
  const selectedChoices = _.filter(_choices, choice => isSelected(needPriorities, choice.id));
  let choices;
  if (selectedChoices.length === 0) {
    choices = _.map(_choices, choice => ({
      ...choice,
      selected: false,
      onClick: selectChoice,
      buttonText: 'Select'
    }));
  } else {
    choices = _.map(_choices, choice => {
      return _.includes(selectedChoices, choice)
        ? { ...choice, selected: true, onClick: deselectChoice, buttonText: 'Deselect' }
        : { ...choice, selected: false, onClick: selectChoice, buttonText: 'Select' };
    });
  }

  return (
    <div id={elementID}>
      <SectionHeader icon={icon} content={question} />
      <Card.Group className={className}>
        {choices.map(
          ({
            selected,
            description,
            pros,
            cons,
            imagePath,
            name,
            subtitle,
            buttonText,
            onClick,
            id
          }) => (
            <ChoiceCard
              key={id}
              selected={selected}
              description={description}
              pros={pros}
              cons={cons}
              imagePath={imagePath}
              name={name}
              subtitle={subtitle}
              buttonText={buttonText}
              onClick={onClick}
              id={id}
              detailsMode={detailsMode}
            />
          )
        )}
      </Card.Group>
    </div>
  );
};

Choice = connect(
  state => {
    return { needPriorities: state.priorities.need, detailsMode: state.detailsMode };
  },
  {
    selectChoice: store.selectChoice,
    deselectChoice: store.deselectChoice,
    populateRecs: store.populateRecs
  }
)(Choice);

const StyledChoice: React.ComponentType<{}> = styled(Choice)`
  margin-left: 0px !important;
`;

export default StyledChoice;
