// @flow

import _ from 'lodash';

import { CHOICE, TEN_POINT, BOOLEAN } from '../utilities/types';
import type { Priority } from '../utilities/types';

export const isDraggable = (priority: Priority): boolean => {
  return priority.rankingType !== CHOICE;
};

const priorities: Priority[] = [
  {
    id: 1,
    title: 'Stylish appearance',
    description: 'Turn heads with good-looking tech.',
    rankingType: TEN_POINT
  },
  {
    id: 2,
    title: 'Hipster appearance',
    description: 'Pairs well with beards and artisan coffee.',
    rankingType: TEN_POINT
  },
  {
    id: 3,
    title: 'Executive appearance',
    description: 'Will match your work blouse. Or your suit.',
    rankingType: TEN_POINT
  },
  {
    id: 4,
    title: 'Long battery life',
    description: 'Only matters for wireless models.',
    rankingType: TEN_POINT
  },
  {
    id: 5,
    title: 'Sound quality',
    description: 'Differs from person to person, but a general assessment is still useful.',
    rankingType: TEN_POINT
  },
  {
    id: 6,
    title: 'Build quality',
    description:
      'How durable they are, and whether nicer materials are used for their construction.',
    rankingType: TEN_POINT
  },
  {
    id: 7,
    title: 'Lightweight',
    description: 'Easier to carry around, easier to wear for long periods of time.',
    rankingType: TEN_POINT
  },
  {
    id: 8,
    title: 'Has a microphone',
    description: 'Lets you make better audio calls.',
    rankingType: BOOLEAN
  },
  {
    id: 9,
    title: 'Has an inline remote',
    description: 'Lets you answer calls, skip tracks, or change volume.',
    rankingType: BOOLEAN
  },
  {
    id: 10,
    title: 'Is water-resistant',
    description: 'Can handle light rain and sweat.',
    rankingType: BOOLEAN
  },
  {
    id: 11,
    title: 'Easy to obtain',
    description:
      'Can be readily purchased online and will ship soon (some headphones take longer to ship).',
    rankingType: TEN_POINT
  },
  {
    id: 12,
    title: 'Strong bass',
    description:
      'Hits the deep, lower notes powerfully. Particularly effective with hip-hop and movie soundtracks.',
    rankingType: TEN_POINT
  },
  {
    id: 13,
    title: 'Comfortable',
    description: 'Differs from person to person, but a general assessment is still useful.',
    rankingType: TEN_POINT
  },
  {
    id: 14,
    title: 'Sound isolation',
    description:
      'How much external sound they block out - the more isolation, the more immersive your music gets.',
    rankingType: TEN_POINT
  },
  {
    id: 15,
    title: 'In-Ears',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 16,
    title: 'On-Ears',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 17,
    title: 'Over-Ears',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 18,
    title: 'Music',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 19,
    title: 'Movies',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 20,
    title: 'Gaming',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 21,
    title: 'Phone Calls',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 22,
    title: 'Wired',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 23,
    title: 'Wireless',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 24,
    title: 'Closed-Back',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 25,
    title: 'Open-Back',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 26,
    title: 'Exercise',
    description: '',
    rankingType: CHOICE
  },
  {
    id: 27,
    title: 'Control scheme',
    description: 'The quality of buttons on the wire or headset.',
    rankingType: TEN_POINT
  },
  {
    id: 28,
    title: 'Case',
    description: 'Helps you keep your nice new pair safe.',
    rankingType: TEN_POINT
  },
  {
    id: 29,
    title: 'Active Noise Cancellation',
    description: 'Blocks out background noise actively, nice for commute and open offices.',
    rankingType: TEN_POINT
  },
  {
    id: 30,
    title: 'Microphone quality',
    description: 'Sound great on all those work Zoom calls.',
    rankingType: TEN_POINT
  }
];

export const draggablePriorities: Priority[] = _.filter(priorities, isDraggable);

export default priorities;
