// @flow

import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { Grid } from 'semantic-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';

import { isDraggable } from '../../../data/priorities';

import * as store from '../../../utilities/store';
import { desktopBuyingGuide, reorderedPriorities } from '../../../utilities/analytics';

import SectionHeader from '../SectionHeader';
import DesktopPrioritySelector from '../PrioritySelector/PrioritySelector';
import PillHeader from './PillHeader';
import PillContainer from './PillContainer';

import type { PriorityMap, Action } from '../../../utilities/types';

const StyledGridRow = styled(Grid.Row)`
  &&&& {
    width: 70vw !important;
  }
`;

type PrioritizerProps = {
  priorities: PriorityMap,
  removePriority: Action,
  reorderPriorities: Action,
  movePriorities: Action,
  populateRecs: Action
};

class Prioritizer extends React.Component<PrioritizerProps> {
  onDragEnd = result => {
    const { source, destination } = result;
    const { reorderPriorities, movePriorities, populateRecs } = this.props;

    if (destination) {
      if (source.droppableId === destination.droppableId) {
        reorderPriorities({
          category: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        });
      } else {
        movePriorities({
          sourceCategory: source.droppableId,
          destinationCategory: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        });
      }
      populateRecs();
      ReactGA.event({
        category: desktopBuyingGuide,
        action: reorderedPriorities,
        label: `Moved from position ${source.index} in ${source.droppableId} to position ${destination.index} in ${destination.droppableId}`
      });
    }
  };

  render() {
    const { priorities, removePriority } = this.props;
    const { need, want, nice } = priorities;
    const [needPills, wantPills, nicePills] = _.map([need, want, nice], selectedPriorities =>
      selectedPriorities.filter(isDraggable)
    );

    // TODO: Make the options for pills small cards that are shown below the search bar, and the
    // search bar allows for filtering through them
    return (
      <div id="prioritizer">
        <SectionHeader
          icon="list ol"
          content={[
            { formatting: 'none', content: 'What ' },
            { formatting: 'emphasis', content: 'else ' },
            { formatting: 'none', content: 'do you care about?' }
          ]}
          subheader="Use the search bar to select your priorities, and drag and drop them to tell us how important they are relative to each other."
        />
        <Grid divided style={{ width: '75vw' }}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <StyledGridRow columns={5}>
              <Grid.Column width={4}>
                <DesktopPrioritySelector />
              </Grid.Column>
              <Grid.Column>
                <PillHeader title="Need" />
                <PillContainer id="need" pills={needPills} closePill={removePriority} />
              </Grid.Column>
              <Grid.Column>
                <PillHeader title="Want" />
                <PillContainer id="want" pills={wantPills} closePill={removePriority} />
              </Grid.Column>
              <Grid.Column>
                <PillHeader title="Would be nice" />
                <PillContainer id="nice" pills={nicePills} closePill={removePriority} />
              </Grid.Column>
            </StyledGridRow>
          </DragDropContext>
        </Grid>
      </div>
    );
  }
}

export default (connect(
  state => {
    return { priorities: state.priorities };
  },
  {
    removePriority: store.removePriority,
    reorderPriorities: store.reorderPriorities,
    movePriorities: store.movePriorities,
    populateRecs: store.populateRecs
  }
)(Prioritizer): React.AbstractComponent<{}>);
