/* eslint-disable no-param-reassign */

import { configureStore, createReducer, createAction } from 'redux-starter-kit';
// Manually installing immer causes problems, perhaps version-number related
// eslint-disable-next-line import/no-extraneous-dependencies
import { original } from 'immer';
import smartSort from './smartSort';

export const setHeadphoneData = createAction('set_headphone_data');

export const selectChoice = createAction('select_choice');
export const deselectChoice = createAction('deselect_choice');

export const addPriority = createAction('add_priority');
export const removePriority = createAction('remove_priority');
export const reorderPriorities = createAction('reorder_priorities');
export const movePriorities = createAction('move_priorities');

export const populateRecs = createAction('populate_recommendations');

export const toggleDetailsMode = createAction('toggle_details_mode');

// Utilities
const removePriorityByID = (list, id) => {
  return list.filter(priority => priority.id !== id);
};

const initialState = {
  priorities: {
    need: [],
    want: [],
    nice: []
  },
  recommendationMap: {
    under_50: [],
    under_100: [],
    under_200: [],
    under_400: [],
    over_400: []
  },
  detailsMode: true
};

const reduceSetHeadphoneData = (state, action) => {
  state.headphones = action.payload;
};

const reduceSelectChoice = (state, action) => {
  state.priorities.need.push(action.payload);
};

const reduceDeselectChoice = (state, action) => {
  state.priorities.need = removePriorityByID(state.priorities.need, action.payload.id);
};

const reduceAddPriority = (state, action) => {
  state.priorities.want.push(action.payload);
};

const reduceRemovePriority = (state, action) => {
  Object.keys(state.priorities).forEach(key => {
    state.priorities[key] = removePriorityByID(state.priorities[key], action.payload.id);
  });
};

const reduceReorderPriorities = (state, action) => {
  const { category, sourceIndex, destinationIndex } = action.payload;
  const categoryPriorities = state.priorities[category];
  const [removed] = categoryPriorities.splice(sourceIndex, 1);
  categoryPriorities.splice(destinationIndex, 0, removed);
};

const reduceMovePriorities = (state, action) => {
  const { sourceCategory, destinationCategory, sourceIndex, destinationIndex } = action.payload;
  const [removed] = state.priorities[sourceCategory].splice(sourceIndex, 1);
  state.priorities[destinationCategory].splice(destinationIndex, 0, removed);
};

const reducePopulateRecs = state => {
  const headphones = original(state.headphones);
  const priorities = original(state.priorities);
  const recommendationMap = smartSort(headphones.slice(), priorities);
  return {
    ...state,
    recommendationMap
  };
};

const reduceToggleDetailsMode = state => {
  return {
    ...state,
    detailsMode: !state.detailsMode
  };
};

const reducer = createReducer(initialState, {
  [setHeadphoneData]: reduceSetHeadphoneData,
  [selectChoice]: reduceSelectChoice,
  [deselectChoice]: reduceDeselectChoice,
  [addPriority]: reduceAddPriority,
  [removePriority]: reduceRemovePriority,
  [reorderPriorities]: reduceReorderPriorities,
  [movePriorities]: reduceMovePriorities,
  [populateRecs]: reducePopulateRecs,
  [toggleDetailsMode]: reduceToggleDetailsMode
});

export const store = configureStore({ reducer });
