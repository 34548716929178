// @flow

import inEars from '../images/alex-blajan-223771-unsplash.jpg';
import onEars from '../images/aiony-haust-667702-unsplash.jpg';
import overEars from '../images/ali-yahya-2xWErKaRV38-unsplash.jpg';

import type { ChoiceSet } from '../utilities/types';

const formFactorChoice: ChoiceSet = {
  question: [
    { formatting: 'none', content: 'What ' },
    { formatting: 'emphasis', content: 'form factor ' },
    { formatting: 'none', content: 'are you looking for?' }
  ],
  elementID: 'form-factor',
  icon: 'headphones',
  choices: [
    {
      id: 15,
      name: 'In-Ears',
      subtitle: 'In-Ear Monitors, Earbuds, Earphones',
      description:
        'They sit in your ear, and are light and portable. They provide good sound isolation, but (relatively) limited sound quality. Some people find the sensation of something in their ear uncomfortable, and finding the right fit can be tricky for some ears, but these are generally comfortable.',
      pros: [
        'light',
        'small',
        'very portable',
        'can be shared',
        'no sound leakage',
        'immersive sound'
      ],
      cons: ['can be uncomfortable', 'easy to lose', 'limited sound quality'],
      imagePath: inEars,
      imageCredit: {
        name: 'Alex Blăjan',
        link:
          'https://unsplash.com/@alexb?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    },
    {
      id: 16,
      name: 'On-Ear Headphones',
      subtitle: 'On-ears',
      description:
        'They sit on your ear, and are lighter and more portable than over-ears. They provide better sound quality than in-ears, but usually worse isolation. These are generally comfortable, but some ears may get hot using these.',
      pros: [
        'comfortable',
        'can be worn with glasses and earrings',
        'good sound',
        'relatively portable'
      ],
      cons: [
        'may become uncomfortable for extended periods of use',
        'low sound isolation',
        'may interfere with certain hairstyles'
      ],
      imagePath: onEars,
      imageCredit: {
        name: 'Aiony Haust',
        link:
          'https://unsplash.com/@aiony?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    },
    {
      id: 17,
      name: 'Over-Ear Headphones',
      subtitle: 'Over-ears, Around-ears',
      description:
        'They sit on the side of your head, surrounding your ear. Despite being the heaviest of the three categories, over-ears are typically the most comfortable for long listening sessions. They provide the highest potential for sound quality, and are usually not considered portable.',
      pros: ['comfortable for long sessions', 'best sound'],
      cons: [
        'may feel heavy on the head',
        'least portable',
        'may interfere with certain hairstyles'
      ],
      imagePath: overEars,
      imageCredit: {
        name: 'Ali Yahya',
        link:
          'https://unsplash.com/@ayahya09?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge'
      }
    }
  ]
};

export default formFactorChoice;
