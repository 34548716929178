// @flow

import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

import { Header, Divider, Button } from 'semantic-ui-react';

import type { FormattedText, StyledProps, Action } from '../../utilities/types';

const formatText = (textRuns: FormattedText[]) => {
  return _.map(textRuns, ({ formatting, content }) =>
    formatting === 'emphasis' ? <span className="emphasis">{content}</span> : <span>{content}</span>
  );
};

type UnstyledSectionHeaderProps = {
  content: FormattedText[],
  subheader?: React.Node,
  buttonText?: string,
  buttonAction?: Action
};

type SectionHeaderProps = UnstyledSectionHeaderProps & StyledProps;

function SectionHeader({
  className,
  content,
  subheader,
  buttonText,
  buttonAction
}: SectionHeaderProps) {
  return (
    <div className={className}>
      <Header size="huge">
        <Header.Content>
          {formatText(content)}
          {buttonText ? <Button primary content={buttonText} onClick={buttonAction} /> : null}
          <Header.Subheader>{subheader}</Header.Subheader>
          <Divider />
        </Header.Content>
      </Header>
    </div>
  );
}

SectionHeader.defaultProps = {
  subheader: null,
  buttonText: null,
  buttonAction: null
};

const StyledSectionHeader: React.ComponentType<UnstyledSectionHeaderProps> = styled(SectionHeader)`
  width: 60vw;
  margin-top: 0.9em;

  span {
    font-weight: 100;
  }
  .emphasis {
    color: hsl(15, 100%, 45%);
    font-weight: bold;
  }

  .content {
    width: 100%;
    .button {
      float: right;
    }
  }
`;

export default StyledSectionHeader;
