// @flow

import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';
import ReactGA from 'react-ga';

import { connect } from 'react-redux';
import { isDraggable } from '../../../data/priorities';

import * as store from '../../../utilities/store';
import { mobileBuyingGuide, reorderedPriorities } from '../../../utilities/analytics';

import SectionHeader from '../SectionHeader';
import PillHeader from './PillHeader';
import PillContainer from './PillContainer';

import type { PriorityMap, Action, StyledProps } from '../../../utilities/types';

type BasePrioritizerProps = {};

type UnstyledPrioritizerProps = BasePrioritizerProps & {
  priorities: PriorityMap,
  removePriority: Action,
  reorderPriorities: Action,
  movePriorities: Action
};

type PrioritizerProps = UnstyledPrioritizerProps & StyledProps;

class Prioritizer extends React.Component<PrioritizerProps> {
  onDragEnd = result => {
    const { source, destination } = result;
    const { reorderPriorities, movePriorities } = this.props;

    if (destination) {
      if (source.droppableId === destination.droppableId) {
        reorderPriorities({
          category: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        });
      } else {
        movePriorities({
          sourceCategory: source.droppableId,
          destinationCategory: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        });
      }
      ReactGA.event({
        category: mobileBuyingGuide,
        action: reorderedPriorities,
        label: `Moved from position ${source.index} in ${source.droppableId} to position ${destination.index} in ${destination.droppableId}`
      });
    }
  };

  render() {
    const { className, removePriority, priorities } = this.props;
    const { need, want, nice } = priorities;
    const [needPills, wantPills, nicePills] = _.map([need, want, nice], selectedPriorities =>
      _.filter(selectedPriorities, isDraggable)
    );

    return (
      <div className={className}>
        <SectionHeader
          icon="list ol"
          content={[
            { formatting: 'none', content: 'What matters ' },
            { formatting: 'emphasis', content: 'the most ' },
            { formatting: 'none', content: 'to you?' }
          ]}
          subheader="Drag and drop your priorities to tell us how important they are relative to each other."
        />
        <div className="grid-container">
          <Grid divided columns="equal">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <PillHeader title="Need" />
                  <PillContainer id="need" pills={needPills} closePill={removePriority} />
                </Grid.Column>
                <Grid.Column>
                  <PillHeader title="Want" />
                  <PillContainer id="want" pills={wantPills} closePill={removePriority} />
                </Grid.Column>
                <Grid.Column>
                  <PillHeader title="Would be nice" />
                  <PillContainer id="nice" pills={nicePills} closePill={removePriority} />
                </Grid.Column>
              </Grid.Row>
            </DragDropContext>
          </Grid>
        </div>
      </div>
    );
  }
}

const StyledPrioritizer: React.ComponentType<UnstyledPrioritizerProps> = styled(Prioritizer)`
  width: 80vw;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .grid-container {
    margin-top: 10px;
  }

  .grid {
    height: 60vh;
    overflow: hidden;
  }

  .column div h2 {
    font-size: 1rem;
  }

  .three.column.row .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

export default (connect(
  state => {
    return { priorities: state.priorities };
  },
  {
    addPriority: store.addPriority,
    removePriority: store.removePriority,
    reorderPriorities: store.reorderPriorities,
    movePriorities: store.movePriorities
  }
)(StyledPrioritizer): React.AbstractComponent<BasePrioritizerProps>);
