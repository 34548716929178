// @flow

import * as React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import MenuBar from '../MenuBar';
import BuyingGuide from '../BuyingGuide';

import Heading from './Heading';
import AppPreview from './AppPreview';
import AboutSection from './AboutSection';

import { mobileBuyingGuide, clickedTakeQuizButton } from '../../../utilities/analytics';

import type { StyledProps } from '../../../utilities/types';

function LandingPage({ className }: StyledProps) {
  const ref = React.createRef();
  const [guideVisible, setGuideVisible] = React.useState(false);
  return (
    <div className={className} ref={ref} style={guideVisible ? { position: 'fixed' } : {}}>
      {guideVisible ? (
        <BuyingGuide onClose={() => setGuideVisible(false)} />
      ) : (
        <>
          <MenuBar
            containerRef={ref}
            onOpenGuide={() => {
              setGuideVisible(true);
              ReactGA.event({
                category: mobileBuyingGuide,
                action: clickedTakeQuizButton
              });
            }}
          />
          <Heading />
          <AppPreview />
          <AboutSection />
        </>
      )}
    </div>
  );
}

const StyledLandingPage: React.ComponentType<{}> = styled(LandingPage)`
  height: 100%;
  width: 100%;
`;

export default StyledLandingPage;
