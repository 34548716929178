// @flow

// The CHOICE ranking type carries true/false data, like the BOOLEAN ranking type
export const BOOLEAN = 'boolean';
export const TEN_POINT = 'ten point scale';
export const CHOICE = 'choice';

// Flow doesn't allow constants in union types
export type RankingType = 'boolean' | 'ten point scale' | 'choice';

export type Priority = {
  id: number,
  title: string,
  description: string,
  rankingType: RankingType
};

// `priorities` maps keys that are strings (containing numbers) to strings.
export type PriorityMap = {
  need: Priority[],
  want: Priority[],
  nice: Priority[]
};

export type Link = {
  site: string,
  url: string
};

export type Recommendation = {
  name: string,
  price: string,
  imagePath: string,
  description: string,
  manufacturerLink: Link,
  distributorLink: Link,
  reviews: Link[]
};

export type HeadphoneData = Recommendation & {
  priorities: PriorityMap
};

// Meant to be used for Redux actions
export type Action = any;

// Meant to be used for onClick functions.
// TODO: probably a more specific way to define this
export type ClickHandler = any;

export type Choice = {
  id: number,
  name: string,
  subtitle: string,
  description: string,
  pros: string[],
  cons: string[],
  imagePath: string,
  imageCredit?: {
    name: string,
    link: string
  }
};

export type FormattedText = {
  formatting: 'none' | 'emphasis',
  content: string
}

export type ChoiceSet = {
  icon: string,
  question: FormattedText[],
  choices: Choice[],
  elementID?: string
};

export type StyledProps = {
  className: string
};

export type RecommendationMap = {
  under_50: Recommendation[],
  under_100: Recommendation[],
  under_200: Recommendation[],
  under_400: Recommendation[],
  over_400: Recommendation[]
};
