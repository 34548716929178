import firebase from 'firebase';
import 'firebase/firestore';

const recommendationRequests = 'recommendation-requests';
const notificationRequests = 'notification-requests';

const firebaseConfig = {
  apiKey: 'AIzaSyB-q54UpGODuH72gXH6i9GRn7uUkFcw5js',
  authDomain: 'headphone-buying-guide.firebaseapp.com',
  projectId: 'headphone-buying-guide'
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

const writeToDB = (table, data) => {
  const createdOn = new Date();
  db.collection(table)
    .add({ createdOn, ...data })
    .then(docRef => {
      console.log(`Document written with ID: ${docRef.id}`);
    })
    .catch(error => {
      console.error(`Error adding document: ${error}`);
    });
};

export const writeRecommendationRequest = (browserData, priorities) => {
  writeToDB(recommendationRequests, {
    browserData,
    priorities
  });
};

export const writeNotificationRequest = (browserData, email, priorities) => {
  writeToDB(notificationRequests, {
    browserData,
    email,
    priorities
  });
};
