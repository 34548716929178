// @flow

import * as React from 'react';
import { List, Icon } from 'semantic-ui-react';

type ProsProps = {
  pros: string[]
};

export function Pros({ pros }: ProsProps) {
  return (
    <List>
      {pros.map((pro, index) => (
        <List.Item key={index}>
          <Icon
            inverted
            name="arrow up"
            color="green"
            size="small"
            style={{ verticalAlign: 'middle' }}
          />
          <List.Content>{pro}</List.Content>
        </List.Item>
      ))}
    </List>
  );
}

type ConsProps = {
  cons: string[]
};

export function Cons({ cons }: ConsProps) {
  return (
    <List>
      {cons.map((con, index) => (
        <List.Item key={index}>
          <Icon name="arrow down" color="red" size="small" style={{ verticalAlign: 'middle' }} />
          <List.Content>{con}</List.Content>
        </List.Item>
      ))}
    </List>
  );
}
