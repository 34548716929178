// @flow

import * as React from 'react';
import styled from 'styled-components';

import { List, Checkbox, Icon } from 'semantic-ui-react';

import type { StyledProps } from '../../utilities/types';

type ChoiceItemProps = {
  openDrawer: any => void,
  name: string,
  selected: boolean,
  onChange: (e: any, data: { checked: boolean }) => void,
  showInfo: boolean,
  drawerContent: React.Node
};

function ChoiceItem(props: ChoiceItemProps & StyledProps) {
  const { openDrawer, name, selected, onChange, showInfo, drawerContent, className } = props;
  return (
    <>
      <List.Item className={className}>
        <List.Content floated="right">
          <Icon size="small" circular name="info" onClick={openDrawer} />
        </List.Content>
        <List.Content onClick={openDrawer}>
          <Checkbox label={name} checked={selected} onClick={openDrawer} onChange={onChange} />
        </List.Content>
      </List.Item>
      {showInfo ? (
        <List.Item>
          <List.Content>{drawerContent}</List.Content>
        </List.Item>
      ) : null}
    </>
  );
}

const StyledChoiceItem = styled(ChoiceItem)`
  .info {
    color: hsl(15, 100%, 45%);
    box-shadow: 0em 0em 0em 0.1em hsla(15, 100%, 25%, 0.1) inset !important;
  }

  .ui.checkbox {
    label::before {
      border: 1px solid hsl(15, 100%, 45%);
      border-color: hsl(15, 100%, 45%) !important;
    }
    input:checked ~ label::after {
      color: hsl(15, 100%, 45%);
    }
  }
`;

export default StyledChoiceItem;
