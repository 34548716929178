// @flow

import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { List, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import * as store from '../../utilities/store';
import {
  mobileBuyingGuide,
  searchedPriorities,
  addedPriority,
  removedPriority
} from '../../utilities/analytics';

import ListItem from './ListItem';
import SectionHeader from './SectionHeader';

import priorities, { isDraggable } from '../../data/priorities';

import type { Priority, Action, StyledProps } from '../../utilities/types';

type BasePrioritySelectorProps = {};

type UnstyledPrioritySelectorProps = BasePrioritySelectorProps & {
  selectedPriorities: Priority[],
  addPriority: Action,
  removePriority: Action
};

type PrioritySelectorProps = StyledProps & UnstyledPrioritySelectorProps;

type PrioritySelectorState = {
  isLoading: boolean,
  results: Priority[],
  value: string,
  infoIndex: number
};

class PrioritySelector extends React.Component<PrioritySelectorProps, PrioritySelectorState> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      results: [],
      value: '',
      infoIndex: -1
    };
  }

  openDrawer = index => {
    this.setState(({ infoIndex }) =>
      infoIndex === index ? { infoIndex: -1 } : { infoIndex: index }
    );
  };

  escapeRegExp = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      const re = new RegExp(this.escapeRegExp(value), 'i');
      const isMatch = result => re.test(result.title);

      this.setState({
        isLoading: false,
        results: _.filter(priorities, isMatch)
      });
    }, 300);
    ReactGA.event({
      category: mobileBuyingGuide,
      action: searchedPriorities,
      value
    });
  };

  render() {
    // const { isLoading, value, results } = this.state;
    // TODO: Make the options for pills small cards that are shown below the search bar,
    // and the search bar allows for filtering through them
    const { className, selectedPriorities, addPriority, removePriority } = this.props;
    const { infoIndex, isLoading, results, value } = this.state;
    const shownPriorities = _.filter(value ? results : priorities, isDraggable);
    return (
      <div className={className}>
        <SectionHeader
          icon="list ol"
          content={[
            { formatting: 'none', content: 'What ' },
            { formatting: 'emphasis', content: 'else ' },
            { formatting: 'none', content: 'do you care about?' }
          ]}
          subheader="Select your priorities. You'll have a chance to tell us how important they are to you on the next page."
        />
        <Input
          fluid
          icon="search"
          placeholder="Search..."
          loading={isLoading}
          onChange={this.handleSearchChange}
        />
        <List relaxed>
          {_.map(shownPriorities, (priority, index) => {
            return (
              <ListItem
                key={priority.id}
                showInfo={index === infoIndex}
                openDrawer={() => this.openDrawer(index)}
                selected={!!_.find(selectedPriorities, ({ id }) => id === priority.id)}
                onChange={(__, { checked }) => {
                  if (checked) {
                    addPriority(priority);
                    ReactGA.event({
                      category: mobileBuyingGuide,
                      action: addedPriority,
                      label: priority.title,
                      value: priority.id
                    });
                  } else {
                    removePriority(priority);
                    ReactGA.event({
                      category: mobileBuyingGuide,
                      action: removedPriority,
                      label: priority.title,
                      value: priority.id
                    });
                  }
                }}
                name={priority.title}
                drawerContent={priority.description}
              />
            );
          })}
        </List>
      </div>
    );
  }
}

type StyledPrioritySelectorType = React.ComponentType<UnstyledPrioritySelectorProps>;
const StyledPrioritySelector: StyledPrioritySelectorType = styled(PrioritySelector)`
  width: 80vw;
  margin: 0 auto;
  height: 100%;

  .fluid.input {
    margin-top: 5vh;
    margin-bottom: 2vh;
  }

  .relaxed.list {
    max-height: 50vh;
    overflow-y: hidden;

    &:hover {
      overflow-y: auto;
    }
  }

  .item .content {
    font-style: italic;
    max-width: 70vw;
    margin: 0 auto;
    line-height: 1.4em;
  }
`;

export default (connect(
  state => {
    return {
      selectedPriorities: [].concat(
        state.priorities.need,
        state.priorities.want,
        state.priorities.nice
      )
    };
  },
  {
    addPriority: store.addPriority,
    removePriority: store.removePriority
  }
)(StyledPrioritySelector): React.AbstractComponent<BasePrioritySelectorProps>);
