// @flow

import * as React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';

import { Header, Segment, Icon } from 'semantic-ui-react';

import Background from '../../../images/alphacolor-2FxEBbEO6Fo-unsplash.jpg';

import type { StyledProps } from '../../../utilities/types';

function AboutSection({ className }: StyledProps) {
  return (
    <div className={className}>
      <Element name="about">
        <Segment inverted size="large">
          <Header as="h2">
            About us
            <Header.Subheader>
              <p>
                Choosy is a tool that aims to help people easily find their next pair of headphones.
              </p>
              <p>
                We don&apos;t display any sponsored content, and we are not affiliated with any
                manufacturer or retailer.
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <p>Created by Neel Kapse.</p>
            </Header.Subheader>
            <div className="contact">
              <a href="https://angel.co/neel-kapse" target="_blank" rel="noopener noreferrer">
                <Icon name="angellist" link color="orange" />
              </a>
              <a href="mailto:neelkapse@gmail.com">
                <Icon name="envelope" link color="orange" />
              </a>
              <a
                href="https://www.linkedin.com/in/neel-kapse-812254b1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="linkedin" link color="orange" />
              </a>
            </div>
          </Header>
        </Segment>
      </Element>
    </div>
  );
}

const StyledAboutSection: React.ComponentType<{}> = styled(AboutSection)`
  min-height: 100vh;
  padding: 1em 0em !important;
  background-image: linear-gradient(hsla(14, 100%, 35%, 0.75), hsla(14, 100%, 35%, 0.75)),
    url(${Background}) !important;
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  color: white;

  .segment {
    width: 75vw;
    max-width: 400px;
    height: 80vh;
    margin: auto !important;
    margin-top: 8vh !important;
    background: rgba(27, 28, 29, 0.8) !important;
    text-align: center;

    .sub.header {
      color: rgba(255, 255, 255, 0.6) !important;
      margin-top: 40px !important;
    }

    .contact {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export default StyledAboutSection;
