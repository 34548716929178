// @flow

import _ from 'lodash';
import * as React from 'react';
import { Tab } from 'semantic-ui-react';
import styled from 'styled-components';

import SectionHeader from '../SectionHeader';
import NoAnswersSegment from './NoAnswersSegment';
import RecommendationTab from './RecommendationTab';

import { findNonEmptyBracketIndex } from '../../utilities/utilities';

import type { RecommendationMap, StyledProps } from '../../../utilities/types';

const bracketToTitle = {
  under_50: '< $50',
  under_100: '< $100',
  under_200: '< $200',
  under_400: '< $400',
  over_400: '> $400'
};

type UnstyledRecommendationPaneProps = {
  recommendationMap: RecommendationMap,
  noPriorities: boolean
};

type RecommendationPaneProps = StyledProps & UnstyledRecommendationPaneProps;

const RecommendationPane = ({
  recommendationMap,
  noPriorities,
  className
}: RecommendationPaneProps) => {
  const nonEmptyBracketIndex = findNonEmptyBracketIndex(recommendationMap);
  return (
    <div className={`mobile-page-container ${className}`}>
      <SectionHeader
        icon="list ol"
        content={[
          { formatting: 'none', content: 'Our ' },
          { formatting: 'emphasis', content: 'recommendations ' },
          { formatting: 'none', content: 'for you!' }
        ]}
      />
      <div style={{ margin: 'auto', width: '100%' }}>
        {noPriorities ? (
          <NoAnswersSegment />
        ) : (
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={_(bracketToTitle)
              .entries()
              .map(([bracket, title]) => ({
                menuItem: title,
                render: () => (
                  <RecommendationTab
                    recommendationMap={recommendationMap}
                    bracket={bracket}
                    key={bracket} // used to force rerender
                  />
                )
              }))
              .value()}
            defaultActiveIndex={nonEmptyBracketIndex}
          />
        )}
      </div>
    </div>
  );
};

type StyledRecommendationPaneType = React.ComponentType<UnstyledRecommendationPaneProps>;

const StyledRecommendationPane: StyledRecommendationPaneType = styled(RecommendationPane)`
  width: 80vw;
  margin: 0 auto;
  height: 100%;

  .drawing-container {
    width: 50vw;
    margin: 50px auto 25px auto;
  }
  .text-container {
    width: 70vw;
    margin: 20px auto 30px auto;
    font-weight: 600;
  }
  .secondary {
    font-weight: 400;
  }
  .ui.secondary.pointing.menu {
    .item {
      padding-left: 0.6em;
      padding-right: 0.6em;
    }
    }
    .ui.text.fluid.container {
      font-size: 0.96rem;
    }
  }
`;

export default StyledRecommendationPane;
