// @flow

import _ from 'lodash';
import * as React from 'react';
import { Card, Tab } from 'semantic-ui-react';

import type { RecommendationMap } from '../../../utilities/types';

import NoResultsSegment from './NoResultsSegment';
import RecommendationCard from './RecommendationCard';

export type RecommendationTabProps = {
  recommendationMap: RecommendationMap,
  bracket: string
};

const RecommendationTab = ({ recommendationMap, bracket }: RecommendationTabProps) => {
  return (
    <Tab.Pane attached style={{ border: 'none' }}>
      {recommendationMap[bracket].length === 0 ? (
        <NoResultsSegment />
      ) : (
        <Card.Group style={{ width: '75vw' }}>
          {_(recommendationMap[bracket])
            .slice(0, 3)
            .map((item, index) => (
              <RecommendationCard key={item.index} {...item} bestOption={index === 0} />
            ))
            .value()}
        </Card.Group>
      )}
    </Tab.Pane>
  );
};

export default RecommendationTab;
