import * as React from 'react';
import styled from 'styled-components';

import Heading from './Heading';
import AppPreview from './AppPreview';
import AboutSection from './AboutSection';

import StyledMenuBar from '../MenuBar';
import BuyingGuide from '../BuyingGuide';

function LandingPage({ className }) {
  const ref = React.createRef();
  return (
    <div className={className} ref={ref}>
      <StyledMenuBar containerRef={ref} />
      <Heading />
      <AppPreview />
      <BuyingGuide />
      <AboutSection />
    </div>
  );
}

const StyledLandingPage = styled(LandingPage)``;

export default StyledLandingPage;
