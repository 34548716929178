// @flow

import * as React from 'react';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

import type { ClickHandler, StyledProps } from '../../../utilities/types';

type UnstyledPillProps = {
  provided: any,
  text: string,
  onClick: ClickHandler,
  index: number
};

type PillProps = StyledProps & UnstyledPillProps;

// A pill that can be reordered and moved
function Pill({ className, provided, text, onClick, index }: PillProps) {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={className}
    >
      <Label size="medium" color="orange">
        <div className="index">{index + 1}</div>
        {text}
        <i className="delete icon" onClick={onClick} />
      </Label>
    </div>
  );
}

const StyledPill: React.ComponentType<UnstyledPillProps> = styled(Pill)`
  padding-bottom: 0.1em;
  .index {
    padding-right: 4px;
    border-right: 1px solid;
    margin-right: 4px;
  }
  .ui.label {
    display: flex;
    i.delete {
      margin-left: auto;
    }
  }
`;

export default StyledPill;
