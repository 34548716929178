// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Divider } from 'semantic-ui-react';

import DownButton from '../DownButton';

import type { ClickHandler, StyledProps } from '../../../utilities/types';

type SectionFooterProps = StyledProps & {
  onClick: ClickHandler
};

function SectionFooter({ className, onClick }: SectionFooterProps) {
  return (
    <div className={className}>
      <Divider />
      <DownButton onClick={onClick} />
    </div>
  );
}

const StyledSectionFooter: React.ComponentType<{}> = styled(SectionFooter)`
  width: 60vw;
`;

export default StyledSectionFooter;
