// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Container, Image, Segment } from 'semantic-ui-react';

import fillForms from '../../../images/undraw_fill_forms_yltj.svg';

import type { StyledProps } from '../../../utilities/types';

const NoAnswersSegment = ({ className }: StyledProps) => {
  return (
    <Segment placeholder className={className}>
      <div className="drawing-container">
        <Image src={fillForms} fluid />
      </div>
      <div className="text-container">
        <Container fluid text>
          {"Oops! Looks like you haven't answered any questions."}
        </Container>
      </div>
      <div className="text-container secondary">
        <Container fluid text>
          {
            "Let us know what you care about in tabs 1-5 and we'll have your recommendations ready here!"
          }
        </Container>
      </div>
    </Segment>
  );
};

const StyledNoAnswersSegment: React.ComponentType<{}> = styled(NoAnswersSegment)`
  margin-top: 15px !important;
`;

export default StyledNoAnswersSegment;
