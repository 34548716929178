// @flow

import * as React from 'react';

type PillHeaderProps = {
  title: string
};

// The header for a pill container
function PillHeader({ title }: PillHeaderProps) {
  return (
    <div>
      <h2>{title}</h2>
    </div>
  );
}

export default PillHeader;
