import * as React from 'react';
import styled from 'styled-components';
import Switch from 'react-switch';
import { connect } from 'react-redux';

import { Icon, Popup } from 'semantic-ui-react';

import * as store from '../../utilities/store';

// eslint-disable-next-line react/prefer-stateless-function
class UnstyledDataModeSwitch extends React.Component {
  render() {
    const { className, detailsMode, toggleDetailsMode } = this.props;
    return (
      <div className={className}>
        <div className="switch-container">
          <div>
            <Popup
              trigger={<Icon size="small" circular name="align left" />}
              content="Show Details"
              size="tiny"
              position="top center"
            />
          </div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            <Switch
              onChange={toggleDetailsMode}
              checked={!detailsMode}
              onColor="#E63900"
              offColor="#E63900"
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={45}
            />
          </label>
          <div>
            <Popup
              trigger={<Icon size="small" circular name="unordered list" />}
              content="Show Pros & Cons"
              size="tiny"
              position="top center"
            />
          </div>
        </div>
      </div>
    );
  }
}

const DataModeSwitch = styled(UnstyledDataModeSwitch)`
  position: fixed;
  top: 28%;
  transform: translateY(-50%);
  left: 2vw;
  width: 20vw;
  display: flex;

  .switch-container {
    display: flex;
    align-items: flex-start;
    margin: auto;
    width: 145px;

    div {
      flex: 1;
      display: flex;
      .icon {
        position: relative;
        margin: auto;
      }
    }
    label {
      flex: 1;
      align-self: flex-end;
    }
  }
`;

export default connect(({ detailsMode }) => ({ detailsMode }), {
  toggleDetailsMode: store.toggleDetailsMode
})(DataModeSwitch);
