// @flow

import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';

import Choice from './Choice/Choice';
import PageContainer from './PageContainer/PageContainer';
import Prioritizer from './Prioritizer/Prioritizer';
import RecommendationPane from './RecommendationPane/RecommendationPane';

import formFactorChoice from '../../data/formFactorChoice';
import useCaseChoice from '../../data/useCaseChoice';
import connectionChoice from '../../data/connectionChoice';
import openClosedChoice from '../../data/openClosedChoice';

import type { PriorityMap, RecommendationMap } from '../../utilities/types';

type BaseBuyingGuideProps = {};

type BuyingGuideProps = BaseBuyingGuideProps & {
  recommendationMap: RecommendationMap,
  priorities: PriorityMap
};

function BuyingGuide(props: BuyingGuideProps) {
  const { priorities, recommendationMap } = props;

  const noPriorities = _(priorities)
    .values()
    .every(_.isEmpty);

  return (
    <div>
      <Element name="buying-guide">
        <PageContainer priorities={priorities}>
          <Choice
            elementID={formFactorChoice.elementID}
            icon={formFactorChoice.icon}
            question={formFactorChoice.question}
            choices={formFactorChoice.choices}
          />
          <Choice
            elementID={useCaseChoice.elementID}
            icon={useCaseChoice.icon}
            question={useCaseChoice.question}
            choices={useCaseChoice.choices}
          />
          <Choice
            elementID={connectionChoice.elementID}
            icon={connectionChoice.icon}
            question={connectionChoice.question}
            choices={connectionChoice.choices}
          />
          <Choice
            elementID={openClosedChoice.elementID}
            icon={openClosedChoice.icon}
            question={openClosedChoice.question}
            choices={openClosedChoice.choices}
          />
          <Prioritizer />
          <RecommendationPane recommendationMap={recommendationMap} noPriorities={noPriorities} />
        </PageContainer>
      </Element>
    </div>
  );
}

export default (connect(({ recommendationMap, priorities }) => {
  return { recommendationMap, priorities };
})(BuyingGuide): React.AbstractComponent<BaseBuyingGuideProps>);
