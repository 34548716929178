// @flow

import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { Icon } from 'semantic-ui-react';

import Choice from './Choice/Choice';
import PrioritySelector from './PrioritySelector';
import Prioritizer from './Prioritizer/Prioritizer';
import RecommendationPane from './RecommendationPane/RecommendationPane';
import Pagination from './Pagination';

import formFactorChoice from '../../data/formFactorChoice';
import useCaseChoice from '../../data/useCaseChoice';
import connectionChoice from '../../data/connectionChoice';
import openClosedChoice from '../../data/openClosedChoice';

import priorityChoices from '../../data/priorities';

import * as store from '../../utilities/store';
import { writeRecs } from '../../utilities/utilities';
import { mobileBuyingGuide, requestedRecommendations } from '../../utilities/analytics';

import type {
  RecommendationMap,
  PriorityMap,
  ChoiceSet,
  ClickHandler,
  StyledProps
} from '../../utilities/types';

type BaseBuyingGuideProps = {};

type BuyingGuideProps = StyledProps &
  BaseBuyingGuideProps & {
    populateRecs: void => void,
    recommendationMap: RecommendationMap,
    priorities: PriorityMap,
    onClose: ClickHandler
  };

type BuyingGuideState = {
  mobilePageIndex: number,
  mobileChoices: ChoiceSet[]
};

class BuyingGuide extends React.Component<BuyingGuideProps, BuyingGuideState> {
  constructor(props: BuyingGuideProps) {
    super(props);
    this.state = {
      mobilePageIndex: 1,
      mobileChoices: [formFactorChoice, useCaseChoice, connectionChoice, openClosedChoice]
    };
  }

  changeMobilePage = (index: number): void => {
    const { priorities, populateRecs } = this.props;
    if (index === 7) {
      populateRecs();
      writeRecs(priorities);
      ReactGA.event({
        category: mobileBuyingGuide,
        action: requestedRecommendations,
        label: '"Next" Button'
      });
    }
    this.setState(state => ({ ...state, mobilePageIndex: index }));
  };

  render() {
    const { mobileChoices, mobilePageIndex } = this.state;
    const { recommendationMap, className, onClose, priorities } = this.props;
    const mobileChoice = mobileChoices[mobilePageIndex - 1];
    const { icon, question, choices } = mobileChoice || {};
    const noPriorities = _(priorities)
      .values()
      .every(_.isEmpty);

    const page = () => {
      switch (mobilePageIndex) {
        case 1:
        case 2:
        case 3:
        case 4:
          return <Choice icon={icon} question={question} choices={choices} />;
        case 5:
          return <PrioritySelector priorityChoices={priorityChoices} />;
        case 6:
          return <Prioritizer priorityChoices={priorityChoices} />;
        case 7:
          return (
            <RecommendationPane recommendationMap={recommendationMap} noPriorities={noPriorities} />
          );
        default:
          return null;
      }
    };

    return (
      <div className={className}>
        <Icon name="close" size="large" onClick={onClose} />
        {page()}
        <Pagination
          pageCount={7}
          activePageIndex={mobilePageIndex}
          changePage={this.changeMobilePage}
        />
      </div>
    );
  }
}

const StyledBuyingGuide = styled(BuyingGuide)`
  height: 100%;
  width: 100%;
  position: absolute;

  .close {
    position: absolute;
    right: 0px;
    margin: 10px;
    color: rgba(0, 0, 0, 0.4);
  }
`;

export default (connect(
  ({ recommendationMap, priorities }) => {
    return { recommendationMap, priorities };
  },
  {
    populateRecs: store.populateRecs
  }
)(StyledBuyingGuide): React.AbstractComponent<BaseBuyingGuideProps>);
