// @flow

import * as React from 'react';
import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

import type { ClickHandler, StyledProps } from '../../../utilities/types';

type UnstyledPillProps = {
  provided: any,
  text: string,
  onClick: ClickHandler,
  small: boolean,
  index: number
};

type PillProps = StyledProps & UnstyledPillProps;

// A pill that can be reordered and moved
function Pill({ className, provided, text, onClick, index }: PillProps) {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={className}
    >
      <Label size="medium" color="orange">
        <div className="index">{index + 1}</div>
        <div>{text}</div>
        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events, jsx-a11y/control-has-associated-label */}
        <i className="delete icon" role="button" onClick={onClick} />
      </Label>
    </div>
  );
}

const StyledPill: React.ComponentType<UnstyledPillProps> = styled(Pill)`
  padding-bottom: 0.1em;
  .index {
    font-size: 12px;
    padding-right: 4px;
    border-right: 1px solid;
    margin-right: 4px;
    line-height: 1.5;
  }
  .ui.label {
    display: flex;
    font-size: 9px !important;
  }
`;

export default StyledPill;
