// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Input } from 'semantic-ui-react';

import { writeNotificationRequest } from '../../utilities/utilities';

import type { PriorityMap } from '../../utilities/types';

type NotificationModalProps = {
  priorities: PriorityMap
};

const NotificationModal = ({ priorities }: NotificationModalProps) => {
  const [email, setEmail] = React.useState();
  const [open, setOpen] = React.useState();

  return (
    <Modal
      size="small"
      open={open}
      trigger={
        <Button primary className="segment-button">
          Notify Us!
        </Button>
      }
    >
      <Modal.Header>Notify Us!</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            {
              "Thanks! We've recorded your priorities and will work on finding a great pair of headphones for you."
            }
          </p>
          <p>
            {
              "You can give us your email address if you'd like to be notified when we've found you the right pair."
            }
          </p>
        </Modal.Description>
        <Input
          action={{
            content: 'Submit',
            onClick: () => {
              if (email) {
                writeNotificationRequest(email, priorities);
                setOpen(false);
              }
            }
          }}
          placeholder="Email"
          style={{
            margin: '20px auto 10px auto',
            transform: 'translateX(-50%)',
            left: '50%'
          }}
          onChange={(event, data) => setEmail(data.value)}
        />
      </Modal.Content>
    </Modal>
  );
};

export default (connect(({ priorities }) => ({ priorities }))(
  NotificationModal
): React.AbstractComponent<{}>);
