// @flow

import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { Container, List } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

import * as store from '../../../utilities/store';
import { desktopBuyingGuide, addedPriority, removedPriority } from '../../../utilities/analytics';

import ListItem from '../ListItem';
import PriorityInput from './PriorityInput';

import priorityChoices, { draggablePriorities, isDraggable } from '../../../data/priorities';

import type { Priority, Action } from '../../../utilities/types';

type BaseDesktopPrioritySelectorProps = {
  priorityChoices: Priority[]
};

type UnstyledDesktopPrioritySelectorProps = BaseDesktopPrioritySelectorProps & {
  selectedPriorities: Priority[],
  addPriority: Action,
  removePriority: Action,
  populateRecs: Action
};

type DesktopPrioritySelectorProps = StyledProps & UnstyledDesktopPrioritySelectorProps;

type DesktopPrioritySelectorState = {
  isLoading: boolean,
  results: Priority[],
  value: string,
  infoIndex: number
};

class DesktopPrioritySelector extends React.Component<
  DesktopPrioritySelectorProps,
  DesktopPrioritySelectorState
> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      results: [],
      value: '',
      infoIndex: -1
    };
  }

  openDrawer = index => {
    this.setState(({ infoIndex }) =>
      infoIndex === index ? { infoIndex: -1 } : { infoIndex: index }
    );
  };

  handleSearchChange = (e, { value }) => {
    const escapeRegExp = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

    this.setState({ isLoading: true, value });

    setTimeout(() => {
      const re = new RegExp(escapeRegExp(value), 'i');
      const isMatch = result => re.test(result.title);

      this.setState({
        isLoading: false,
        results: _(priorityChoices)
          .filter(isDraggable)
          .filter(isMatch)
          .value()
      });
    }, 300);
  };

  render() {
    // const { isLoading, value, results } = this.state;
    // TODO: Make the options for pills small cards that are shown below the search bar,
    // and the search bar alloswws for filtering through them
    const { className, selectedPriorities, addPriority, removePriority, populateRecs } = this.props;
    const { infoIndex, isLoading, results, value } = this.state;
    const shownPriorities = value ? results : draggablePriorities;
    return (
      <Container className={className}>
        <PriorityInput isLoading={isLoading} handleSearchChange={this.handleSearchChange} />
        <List celled style={{ height: '70vh', overflowY: 'auto' }}>
          {shownPriorities.map((priority, index) => {
            return (
              <ListItem
                key={priority.id}
                showInfo={index === infoIndex}
                openDrawer={() => this.openDrawer(index)}
                selected={!!_.find(selectedPriorities, ({ id }) => id === priority.id)}
                onChange={(__, { checked }) => {
                  if (checked) {
                    addPriority(priority);
                    populateRecs();
                    ReactGA.event({
                      category: desktopBuyingGuide,
                      action: addedPriority,
                      label: priority.title,
                      value: priority.id
                    });
                  } else {
                    removePriority(priority);
                    populateRecs();
                    ReactGA.event({
                      category: desktopBuyingGuide,
                      action: removedPriority,
                      label: priority.title,
                      value: priority.id
                    });
                  }
                }}
                name={priority.title}
                description={priority.description}
              />
            );
          })}
        </List>
      </Container>
    );
  }
}

const StyledDesktopPrioritySelector = styled(DesktopPrioritySelector)`
  .ui.celled.list {
    overflow-y: hidden;

    &:hover {
      overflow-y: auto;
    }
  }
`;

export default (connect(
  state => {
    return {
      selectedPriorities: _.concat(
        state.priorities.need,
        state.priorities.want,
        state.priorities.nice
      )
    };
  },
  {
    addPriority: store.addPriority,
    removePriority: store.removePriority,
    populateRecs: store.populateRecs
  }
)(StyledDesktopPrioritySelector): React.AbstractComponent<BaseDesktopPrioritySelectorProps>);
