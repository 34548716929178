// @flow

import _ from 'lodash';
import * as React from 'react';
import { Container, Card, Button, Tab, Image, Icon, List, Divider } from 'semantic-ui-react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import Description from '../../shared/Description';

import type { Recommendation, StyledProps } from '../../../utilities/types';

function importAll(r) {
  const images = [];
  _.forEach(r.keys(), item => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

// Import all image files in the headphones folder (regex matches every non-empty string)
// $FlowFixMe
const images: Object = importAll(require.context('../../../images/headphones', true, /.*/));

type UnstyledRecommendationCardProps = Recommendation;

type RecommendationCardProps = StyledProps & UnstyledRecommendationCardProps;

function RecommendationCard({
  name,
  price,
  className,
  imagePath,
  description,
  manufacturerLink,
  distributorLink,
  reviews
}: RecommendationCardProps) {
  const [showDescription, setShowDescription] = React.useState(false);

  const panes = [
    {
      menuItem: 'Summary',
      render: () => (
        <Tab.Pane as={Container}>
          <p>{description}</p>
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Learn More',
      render: () => (
        <Tab.Pane as={Container}>
          <List>
            <List.Item>
              <ReactGA.OutboundLink
                eventLabel={`Manufacturer: ${manufacturerLink.url}`}
                to={manufacturerLink.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {manufacturerLink.site}
              </ReactGA.OutboundLink>
            </List.Item>
            {!_.isEmpty(reviews) ? <Divider className="review-divider" /> : null}
            {_.map(reviews, review => (
              <List.Item>
                <ReactGA.OutboundLink
                  eventLabel={`Review: ${review.url}`}
                  to={review.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {review.site}
                </ReactGA.OutboundLink>
              </List.Item>
            ))}
          </List>
        </Tab.Pane>
      )
    }
  ];

  return (
    <Card className={className}>
      <Card.Content>
        <Card.Header>
          {name}
          <Icon
            size="small"
            circular
            name={showDescription ? 'image' : 'info'}
            onClick={() => setShowDescription(!showDescription)}
          />
        </Card.Header>
        {!showDescription ? (
          <Image fluid src={images[`${imagePath}`]} />
        ) : (
          <Card.Description style={{ padding: '10px' }}>
            <Description panes={panes} />
          </Card.Description>
        )}
      </Card.Content>
      <Card.Content extra>
        <div className="extra-content">
          <div>${price}</div>
          <Button
            primary
            style={{ letterSpacing: '0.07em' }}
            href={distributorLink.url}
            target="_blank"
          >
            <ReactGA.OutboundLink
              eventLabel={`Buy: ${distributorLink.url}`}
              to={distributorLink.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Buy
            </ReactGA.OutboundLink>
          </Button>
        </div>
      </Card.Content>
    </Card>
  );
}

type StyledRecommendationCardType = React.ComponentType<UnstyledRecommendationCardProps>;
const StyledRecommendationCard: StyledRecommendationCardType = styled(RecommendationCard)`
  margin: auto !important;
  margin-bottom: 10px !important;
  height: 55vh;

  .content .header {
    display: flex !important;
    margin-bottom: 15px;
    .icon {
      margin-left: auto;
    }
  }

  .extra-content {
    display: flex;
    div {
      font-weight: 800;
      color: rgba(0, 0, 0, 0.85);
      font-size: 1.6em;
      vertical-align: middle;
      line-height: 36px;
      flex-grow: 2;
    }
  }

  .review-divider.review-divider.review-divider {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export default StyledRecommendationCard;
