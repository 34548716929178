// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Step as SemanticStep } from 'semantic-ui-react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { scrollToSection } from '../../utilities/utilities';
import { writeRecs } from '../../../utilities/utilities';
import * as store from '../../../utilities/store';
import { desktopBuyingGuide, requestedRecommendations } from '../../../utilities/analytics';

import Step from './Step';

import type { PriorityMap, Action, StyledProps } from '../../../utilities/types';

type BaseNavProps = {
  scrollY: number,
  priorities: PriorityMap
};

type UnstyledNavProps = BaseNavProps & {
  populateRecs: Action
};

type NavProps = StyledProps & UnstyledNavProps;

function Nav({ className, scrollY, priorities, populateRecs }: NavProps) {
  const pageStartOffset = 815;
  const relativeScrollY = scrollY - pageStartOffset;

  // $FlowFixMe
  const getOffset = id => document.getElementById(id).offsetTop - 70;

  const useCaseScrollY = getOffset('use-case');
  const connectionScrollY = getOffset('connection');
  const openClosedScrollY = getOffset('open-closed');
  const prioritizerScrollY = getOffset('prioritizer');
  const recommendationPaneScrollY = getOffset('recommendation-pane');

  const recommendationCallback = () => {
    ReactGA.event({
      category: desktopBuyingGuide,
      action: requestedRecommendations,
      label: 'Nav Button'
    });
    scrollToSection('scroll-element-5')();
    populateRecs();
    writeRecs(priorities);
  };

  return (
    <SemanticStep.Group vertical className={className} size="tiny">
      <Step
        active={relativeScrollY < useCaseScrollY}
        onClick={scrollToSection('scroll-element-0')}
        title="Form Factor"
      />

      <Step
        active={relativeScrollY >= useCaseScrollY && relativeScrollY < connectionScrollY}
        onClick={scrollToSection('scroll-element-1')}
        title="Use Cases"
      />

      <Step
        active={relativeScrollY >= connectionScrollY && relativeScrollY < openClosedScrollY}
        onClick={scrollToSection('scroll-element-2')}
        title="Connection Type"
      />

      <Step
        active={relativeScrollY >= openClosedScrollY && relativeScrollY < prioritizerScrollY}
        onClick={scrollToSection('scroll-element-3')}
        title="Openness"
      />

      <Step
        active={
          relativeScrollY >= prioritizerScrollY && relativeScrollY < recommendationPaneScrollY
        }
        onClick={scrollToSection('scroll-element-4')}
        title="Priorities"
        description="Select and order your priorities"
      />

      <Step
        active={relativeScrollY >= recommendationPaneScrollY}
        onClick={recommendationCallback}
        title="Get Recommendations"
        className="get-recommendations"
      />
    </SemanticStep.Group>
  );
}

const StyledNav: React.ComponentType<UnstyledNavProps> = styled(Nav)`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 2vw;
  width: 20vw;

  .step.get-recommendations {
    & > div {
      background-color: hsl(14, 100%, 45%);
      padding: 1em;
      border-radius: 0.28571429rem;
      width: 100%;
      color: #fff;
    }
    &.active .title {
      color: #fff !important;
    }
  }
`;

export default (connect(null, { populateRecs: store.populateRecs })(
  StyledNav
): React.AbstractComponent<BaseNavProps>);
