// @flow

import * as React from 'react';

import type { StyledProps } from '../../../utilities/types';

type UnstyledPillHeaderProps = {
  title: string
};

type PillHeaderProps = StyledProps & UnstyledPillHeaderProps;

// The header for a pill container
function PillHeader({ title, className }: PillHeaderProps) {
  return (
    <div className={className}>
      <h2>{title}</h2>
    </div>
  );
}

export default PillHeader;
