// @flow

import _ from 'lodash';

import priorities from '../data/priorities';
import { BOOLEAN, CHOICE } from './types';
import { nonPriorityFields } from './constants';

import type {
  HeadphoneData,
  PriorityMap,
  Priority,
  Recommendation,
  RecommendationMap
} from './types';

import formFactorChoice from '../data/formFactorChoice';
import useCaseChoice from '../data/useCaseChoice';
import connectionChoice from '../data/connectionChoice';
import openClosedChoice from '../data/openClosedChoice';

const formatRecommendations = (recommendations: Recommendation[]): RecommendationMap => {
  return _(recommendations)
    .map(recommendation => _.pick(recommendation, nonPriorityFields))
    .reduce(
      (acc, hp) => {
        const price = parseInt(hp.price, 10);
        if (price <= 50) {
          return { ...acc, under_50: acc.under_50.concat([hp]) };
        } else if (price <= 100) {
          return { ...acc, under_100: acc.under_100.concat([hp]) };
        } else if (price <= 200) {
          return { ...acc, under_200: acc.under_200.concat([hp]) };
        } else if (price <= 400) {
          return { ...acc, under_400: acc.under_400.concat([hp]) };
        } else {
          return { ...acc, over_400: acc.over_400.concat([hp]) };
        }
      },
      {
        under_50: [],
        under_100: [],
        under_200: [],
        under_400: [],
        over_400: []
      }
    );
};

/**
 * Returns the set of recommended headphones, in descending order of strength of recommendation,
 * based on the priorities passed in.
 * @param {HeadphoneData[]} headphones - The list of all possible recommendable headphones
 * @param {PriorityMap} selectedPriorities - The list of user priorities
 */
export default function smartSort(
  headphones: HeadphoneData[],
  selectedPriorities: PriorityMap
): RecommendationMap {
  /**
   * Algorithm:
   *
   * Immediately filter headphone list to only include models that meet all the boolean
   * "Need" criteria
   * Assign a weight to each priority:
   * - Priorities positioned higher in their category (need/want/nice) get higher weighting
   * - All priorities in a higher category are greater by at least some offset (say, 5 weight units)
   * than the priorities in a lower category
   * - boolean priorities are included in the weighting, but their boolean-ness is normalized so the
   * same equation can be used
   * For each remaining headphone model, compute a score based on the priority values and weights
   * Sort by score
   */

  // Filter list to only include models that meet all the boolean "Need" criteria
  let recommendation = headphones;
  _.forEach(selectedPriorities.need, need => {
    if (need.rankingType === BOOLEAN) {
      recommendation = _.filter(
        recommendation,
        headphone => headphone.priorities[need.id] === 'TRUE'
      );
    }
  });

  const choicePriorities = _.map(
    _.filter(selectedPriorities.need, ({ rankingType }) => rankingType === CHOICE),
    'id'
  );

  const choiceIDLists = _.filter(
    _.map([formFactorChoice, useCaseChoice, connectionChoice, openClosedChoice], ({ choices }) =>
      _.intersection(choicePriorities, _.map(choices, 'id'))
    ),
    choiceIDList => !_.isEmpty(choiceIDList)
  );

  _.forEach(choiceIDLists, choiceIDList => {
    recommendation = _.filter(recommendation, headphone =>
      _.some(_.map(choiceIDList, id => headphone.priorities[id] === 'TRUE'))
    );
  });

  // Create weights vector
  // Algorithm used:
  //   within a category, priority weights are increased by 1 for each increase in position.
  //   between categories, the categoryOffset is added to increase difference in weights
  // TODO: optimize weights
  const numSelectedPriorities =
    selectedPriorities.need.length +
    selectedPriorities.want.length +
    selectedPriorities.nice.length;
  const weights = new Array(priorities.length).fill(0);
  const categoryOffset = 4;
  let currentWeight = numSelectedPriorities + 2 * categoryOffset;

  // $FlowFixMe
  _(selectedPriorities)
    .values()
    .forEach((category: Priority[]) => {
      category.forEach(({ id }) => {
        weights[id] = currentWeight;
        currentWeight -= 1;
      });
      currentWeight -= categoryOffset;
    });

  // Compute recommendation strengths
  // Algorithm used:
  //   Weight boolean yes/no as 7 and 3
  // TODO: optimize weights
  const recommendationWithStrengths: any = recommendation.map(headphone => {
    return {
      ...headphone,
      recommendationStrength: _.reduce(weights, (acc, weight, index) => {
        let value = headphone.priorities[index];
        if (_.includes([BOOLEAN, CHOICE], priorities[index - 1].rankingType)) {
          value = value === 'true' ? 7 : 3;
        } else {
          value = parseInt(value, 10);
        }
        return acc + weight * value;
      })
    };
  });

  // Sort recommendations in decreasing order of strength
  recommendationWithStrengths.sort((hp1, hp2) => {
    return hp2.recommendationStrength - hp1.recommendationStrength;
  });

  return formatRecommendations(recommendationWithStrengths);
}
